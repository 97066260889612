export enum LOB {
    Partnership = 'partnership',
    Electronics = 'electronics',
    Internet = 'internet',
}

export enum PartnershipOnboardingStages {
    Welcome = 'welcome',
    PersonalDetails = 'personalDetails',
    CarDetails = 'carDetails',
    FinalScreen = 'finalScreen',
}

export enum API_TYPE {
    SEND_OTP = 'send_otp',
    VERIFY_OTP = 'verify_otp',
}

export enum INPUT_TYPE {
    EMAIL = 'Email',
    MOBILE_NUMBER = 'Mobile number',
}

export const SEND_OTP_BASE_URL = {
    EMAIL: '/auth/api/email/send-otp/',
    MOBILE_NUMBER: '/auth/api/send-otp/',
};

export const VERIFY_OTP_BASE_URL = {
    EMAIL: '/auth/api/email/verify-otp/',
    MOBILE_NUMBER: '/auth/api/verify-otp/',
};

export const ERROR_TYPE = {
    INCORRECT_OTP: 'Incorrect OTP',
    MANY_ATTEMPTS:
        'Hey there, too many login attempts! Please try again after 5 minutes.',
};

export const Relations = [
    'Self',
    'Brother',
    'Mother',
    'Father',
    'Sister',
    'Husband',
    'Wife',
    'Son',
    'Daughter',
    'Mother-in-Law',
    'Father-in-Law',
    'Son-in-Law',
    'Daughter-in-Law',
    'Brother-in-Law',
    'Sister-in-Law',
];

export const Genders = ['Male', 'Female', 'Other'];

export const CITY_ARRAY = [
    { value: 'aambala', displayValue: 'Aambala' },
    { value: 'abohar', displayValue: 'Abohar' },
    { value: 'abu', displayValue: 'Abu' },
    { value: 'achalpur', displayValue: 'Achalpur' },
    { value: 'adilabad', displayValue: 'Adilabad' },
    { value: 'adoni', displayValue: 'Adoni' },
    { value: 'agartala', displayValue: 'Agartala' },
    { value: 'agra', displayValue: 'Agra' },
    { value: 'ahmadabad', displayValue: 'Ahmadabad' },
    { value: 'ahmadnagar', displayValue: 'Ahmadnagar' },
    { value: 'ahmedabad', displayValue: 'Ahmedabad' },
    { value: 'ahmednagar', displayValue: 'Ahmednagar' },
    { value: 'ainapur', displayValue: 'Ainapur' },
    { value: 'aizawl', displayValue: 'Aizawl' },
    { value: 'aizwal', displayValue: 'Aizwal' },
    { value: 'ajanta', displayValue: 'Ajanta' },
    { value: 'ajmer', displayValue: 'Ajmer' },
    { value: 'akbarpur', displayValue: 'Akbarpur' },
    { value: 'akkalkot', displayValue: 'Akkalkot' },
    { value: 'akola', displayValue: 'Akola' },
    { value: 'alangudi', displayValue: 'Alangudi' },
    { value: 'alappuzha', displayValue: 'Alappuzha' },
    { value: 'alibaug', displayValue: 'Alibaug' },
    { value: 'aligarh', displayValue: 'Aligarh' },
    { value: 'alipore', displayValue: 'Alipore' },
    { value: 'alipur_duar', displayValue: 'Alipur Duar' },
    { value: 'alipurduar', displayValue: 'Alipurduar' },
    { value: 'allahabad', displayValue: 'Allahabad' },
    { value: 'alleppey', displayValue: 'Alleppey' },
    { value: 'almora', displayValue: 'Almora' },
    { value: 'alsisar', displayValue: 'Alsisar' },
    { value: 'alur', displayValue: 'Alur' },
    { value: 'alwar', displayValue: 'Alwar' },
    { value: 'amalapuram', displayValue: 'Amalapuram' },
    { value: 'amalner', displayValue: 'Amalner' },
    { value: 'amaravati', displayValue: 'Amaravati' },
    { value: 'ambaji', displayValue: 'Ambaji' },
    { value: 'ambala', displayValue: 'Ambala' },
    { value: 'ambikapur', displayValue: 'Ambikapur' },
    { value: 'ambur', displayValue: 'Ambur' },
    { value: 'amer', displayValue: 'Amer' },
    { value: 'amla', displayValue: 'Amla' },
    { value: 'amravati', displayValue: 'Amravati' },
    { value: 'amreli', displayValue: 'Amreli' },
    { value: 'amritsar', displayValue: 'Amritsar' },
    { value: 'amroha', displayValue: 'Amroha' },
    { value: 'anand', displayValue: 'Anand' },
    { value: 'anantapur', displayValue: 'Anantapur' },
    { value: 'anantnag', displayValue: 'Anantnag' },
    { value: 'angara', displayValue: 'Angara' },
    { value: 'anjangaon', displayValue: 'Anjangaon' },
    { value: 'anjar', displayValue: 'Anjar' },
    { value: 'ankleshwar bypass', displayValue: 'Ankleshwar Bypass' },
    { value: 'ankleshwar', displayValue: 'Ankleshwar' },
    { value: 'annavasal', displayValue: 'Annavasal' },
    { value: 'annur', displayValue: 'Annur' },
    { value: 'ara', displayValue: 'Ara' },
    { value: 'arakonam', displayValue: 'Arakonam' },
    { value: 'arambag', displayValue: 'Arambag' },
    { value: 'arambagh', displayValue: 'Arambagh' },
    { value: 'aranthangi', displayValue: 'Aranthangi' },
    { value: 'araria', displayValue: 'Araria' },
    { value: 'arcot', displayValue: 'Arcot' },
    { value: 'arni', displayValue: 'Arni' },
    { value: 'aroor', displayValue: 'Aroor' },
    { value: 'arrah', displayValue: 'Arrah' },
    { value: 'arsikere', displayValue: 'Arsikere' },
    { value: 'aruppukkottai', displayValue: 'Aruppukkottai' },
    { value: 'arvi', displayValue: 'Arvi' },
    { value: 'asansol', displayValue: 'Asansol' },
    { value: 'ashoknagar', displayValue: 'Ashoknagar' },
    { value: 'ashta_mp', displayValue: 'Ashta (MP)' },
    { value: 'ashta', displayValue: 'Ashta' },
    { value: 'ashtamudi', displayValue: 'Ashtamudi' },
    { value: 'ashti', displayValue: 'Ashti' },
    { value: 'atmakur', displayValue: 'Atmakur' },
    { value: 'atrauli', displayValue: 'Atrauli' },
    { value: 'attingal', displayValue: 'Attingal' },
    { value: 'attur', displayValue: 'Attur' },
    { value: 'auli', displayValue: 'Auli' },
    { value: 'aundha_nagnath', displayValue: 'Aundha Nagnath' },
    { value: 'auraiya', displayValue: 'Auraiya' },
    { value: 'aurangabad_bihar', displayValue: 'Aurangabad, Bihar' },
    { value: 'aurangabad', displayValue: 'Aurangabad' },
    { value: 'ayodhya', displayValue: 'Ayodhya' },
    { value: 'azamgarh', displayValue: 'Azamgarh' },
    { value: 'badami', displayValue: 'Badami' },
    { value: 'baddi', displayValue: 'Baddi' },
    { value: 'badrinath', displayValue: 'Badrinath' },
    { value: 'bagalkot', displayValue: 'Bagalkot' },
    { value: 'baghpat', displayValue: 'Baghpat' },
    { value: 'bagnan', displayValue: 'Bagnan' },
    { value: 'bagula', displayValue: 'Bagula' },
    { value: 'bahadurgarh', displayValue: 'Bahadurgarh' },
    { value: 'baharampur', displayValue: 'Baharampur' },
    { value: 'baheri', displayValue: 'Baheri' },
    { value: 'bahraich', displayValue: 'Bahraich' },
    { value: 'baikunthpur', displayValue: 'Baikunthpur' },
    { value: 'bailhongal', displayValue: 'Bailhongal' },
    { value: 'balaghat', displayValue: 'Balaghat' },
    { value: 'balangir', displayValue: 'Balangir' },
    { value: 'balapur', displayValue: 'Balapur' },
    { value: 'balasinor', displayValue: 'Balasinor' },
    { value: 'balasore', displayValue: 'Balasore' },
    { value: 'baleshwar', displayValue: 'Baleshwar' },
    { value: 'ballari', displayValue: 'Ballari' },
    { value: 'ballarpur', displayValue: 'Ballarpur' },
    { value: 'ballia', displayValue: 'Ballia' },
    { value: 'bally', displayValue: 'Bally' },
    { value: 'balotra', displayValue: 'Balotra' },
    { value: 'balrampur', displayValue: 'Balrampur' },
    { value: 'balurghat', displayValue: 'Balurghat' },
    { value: 'bamaniya', displayValue: 'Bamaniya' },
    { value: 'bambora', displayValue: 'Bambora' },
    { value: 'banda', displayValue: 'Banda' },
    { value: 'bandhavgarh', displayValue: 'Bandhavgarh' },
    { value: 'bandipur', displayValue: 'Bandipur' },
    { value: 'bangalore', displayValue: 'Bangalore' },
    { value: 'bangaon', displayValue: 'Bangaon' },
    { value: 'banka', displayValue: 'Banka' },
    { value: 'bankura', displayValue: 'Bankura' },
    { value: 'banswara', displayValue: 'Banswara' },
    { value: 'bantwal', displayValue: 'Bantwal' },
    { value: 'bapatla', displayValue: 'Bapatla' },
    { value: 'bara_banki', displayValue: 'Bara Banki' },
    { value: 'barabanki', displayValue: 'Barabanki' },
    { value: 'baramati', displayValue: 'Baramati' },
    { value: 'baramula', displayValue: 'Baramula' },
    { value: 'baran', displayValue: 'Baran' },
    { value: 'baranagar', displayValue: 'Baranagar' },
    { value: 'barara', displayValue: 'Barara' },
    { value: 'barasat', displayValue: 'Barasat' },
    { value: 'barauni', displayValue: 'Barauni' },
    { value: 'baraut', displayValue: 'Baraut' },
    { value: 'barbil', displayValue: 'Barbil' },
    { value: 'bardoli', displayValue: 'Bardoli' },
    { value: 'bareilly', displayValue: 'Bareilly' },
    { value: 'baripada', displayValue: 'Baripada' },
    { value: 'barmer', displayValue: 'Barmer' },
    { value: 'barnala', displayValue: 'Barnala' },
    { value: 'barrackpore', displayValue: 'Barrackpore' },
    { value: 'barshi', displayValue: 'Barshi' },
    { value: 'barwala', displayValue: 'Barwala' },
    { value: 'barwani', displayValue: 'Barwani' },
    { value: 'basavakalyan', displayValue: 'Basavakalyan' },
    { value: 'basirhat', displayValue: 'Basirhat' },
    { value: 'basti', displayValue: 'Basti' },
    { value: 'batala', displayValue: 'Batala' },
    { value: 'bathinda', displayValue: 'Bathinda' },
    { value: 'beawar', displayValue: 'Beawar' },
    { value: 'beed', displayValue: 'Beed' },
    { value: 'beerapally', displayValue: 'Beerapally' },
    { value: 'begusarai', displayValue: 'Begusarai' },
    { value: 'behror', displayValue: 'Behror' },
    { value: 'bela_pratapgarh', displayValue: 'Bela Pratapgarh' },
    { value: 'belagavi', displayValue: 'Belagavi' },
    { value: 'belgaum', displayValue: 'Belgaum' },
    { value: 'bellary', displayValue: 'Bellary' },
    { value: 'belur', displayValue: 'Belur' },
    { value: 'bengaluru', displayValue: 'Bengaluru' },
    { value: 'berhampore', displayValue: 'Berhampore' },
    { value: 'berhampur', displayValue: 'Berhampur' },
    { value: 'betalghat', displayValue: 'Betalghat' },
    { value: 'bettiah', displayValue: 'Bettiah' },
    { value: 'betul', displayValue: 'Betul' },
    { value: 'bhadohi', displayValue: 'Bhadohi' },
    { value: 'bhadra', displayValue: 'Bhadra' },
    { value: 'bhadrachalam', displayValue: 'Bhadrachalam' },
    { value: 'bhadrak', displayValue: 'Bhadrak' },
    { value: 'bhadravati', displayValue: 'Bhadravati' },
    { value: 'bhagalpur', displayValue: 'Bhagalpur' },
    { value: 'bhandara', displayValue: 'Bhandara' },
    { value: 'bhandardara', displayValue: 'Bhandardara' },
    { value: 'bharatpur', displayValue: 'Bharatpur' },
    { value: 'bharhut', displayValue: 'Bharhut' },
    { value: 'bharuch_bypass', displayValue: 'Bharuch Bypass' },
    { value: 'bharuch', displayValue: 'Bharuch' },
    { value: 'bhatapara', displayValue: 'Bhatapara' },
    { value: 'bhatpara', displayValue: 'Bhatpara' },
    { value: 'bhavangadh', displayValue: 'Bhavangadh' },
    { value: 'bhavnagar', displayValue: 'Bhavnagar' },
    { value: 'bhawanipatna', displayValue: 'Bhawanipatna' },
    { value: 'bhilai', displayValue: 'Bhilai' },
    { value: 'bhilwara', displayValue: 'Bhilwara' },
    { value: 'bhimtal', displayValue: 'Bhimtal' },
    { value: 'bhind', displayValue: 'Bhind' },
    { value: 'bhinmal', displayValue: 'Bhinmal' },
    { value: 'bhiwadi', displayValue: 'Bhiwadi' },
    { value: 'bhiwani', displayValue: 'Bhiwani' },
    { value: 'bhojpur', displayValue: 'Bhojpur' },
    { value: 'bhopal', displayValue: 'Bhopal' },
    { value: 'bhubaneshwar', displayValue: 'Bhubaneshwar' },
    { value: 'bhuj', displayValue: 'Bhuj' },
    { value: 'bhusawal', displayValue: 'Bhusawal' },
    { value: 'biaora', displayValue: 'Biaora' },
    { value: 'bid', displayValue: 'Bid' },
    { value: 'bidar', displayValue: 'Bidar' },
    { value: 'bihar sharif', displayValue: 'Bihar Sharif' },
    { value: 'bihta', displayValue: 'Bihta' },
    { value: 'bijapur', displayValue: 'Bijapur' },
    { value: 'bijnor', displayValue: 'Bijnor' },
    { value: 'bikaner', displayValue: 'Bikaner' },
    { value: 'bilaspur', displayValue: 'Bilaspur' },
    { value: 'bilimora', displayValue: 'Bilimora' },
    { value: 'binsar', displayValue: 'Binsar' },
    { value: 'bir', displayValue: 'Bir' },
    { value: 'bishnupur', displayValue: 'Bishnupur' },
    { value: 'biswanath_chariali', displayValue: 'Biswanath Chariali' },
    { value: 'bithur', displayValue: 'Bithur' },
    { value: 'bodh_gaya', displayValue: 'Bodh Gaya' },
    { value: 'bodhgaya', displayValue: 'Bodhgaya' },
    { value: 'bodinayakanur', displayValue: 'Bodinayakanur' },
    { value: 'bokaro', displayValue: 'Bokaro' },
    { value: 'bolpur', displayValue: 'Bolpur' },
    { value: 'bongaigaon', displayValue: 'Bongaigaon' },
    { value: 'brahmapur', displayValue: 'Brahmapur' },
    { value: 'budaun', displayValue: 'Budaun' },
    { value: 'budge_budge', displayValue: 'Budge Budge' },
    { value: 'bulandshahr', displayValue: 'Bulandshahr' },
    { value: 'buldhana', displayValue: 'Buldhana' },
    { value: 'bundi', displayValue: 'Bundi' },
    { value: 'burdwan', displayValue: 'Burdwan' },
    { value: 'burhanpur', displayValue: 'Burhanpur' },
    { value: 'burhar', displayValue: 'Burhar' },
    { value: 'buxar', displayValue: 'Buxar' },
    { value: 'calicut', displayValue: 'Calicut' },
    { value: 'canannore', displayValue: 'Canannore' },
    { value: 'chaibasa', displayValue: 'Chaibasa' },
    { value: 'chail', displayValue: 'Chail' },
    { value: 'chakdaha', displayValue: 'Chakdaha' },
    { value: 'chalisgaon', displayValue: 'Chalisgaon' },
    { value: 'challakere', displayValue: 'Challakere' },
    { value: 'chamarajanagar', displayValue: 'Chamarajanagar' },
    { value: 'chamba', displayValue: 'Chamba' },
    { value: 'chandausi', displayValue: 'Chandausi' },
    { value: 'chandernagore', displayValue: 'Chandernagore' },
    { value: 'chandigarh', displayValue: 'Chandigarh' },
    { value: 'chandole', displayValue: 'Chandole' },
    { value: 'chandpur', displayValue: 'Chandpur' },
    { value: 'chandragiri', displayValue: 'Chandragiri' },
    { value: 'chandrapur', displayValue: 'Chandrapur' },
    { value: 'channarayapatna', displayValue: 'Channarayapatna' },
    { value: 'chapra', displayValue: 'Chapra' },
    { value: 'charkhi dadri', displayValue: 'Charkhi Dadri' },
    { value: 'chauri_chaura', displayValue: 'Chauri Chaura' },
    { value: 'cheeka', displayValue: 'Cheeka' },
    { value: 'chengalpattu', displayValue: 'Chengalpattu' },
    { value: 'chennai', displayValue: 'Chennai' },
    { value: 'cherrapunji', displayValue: 'Cherrapunji' },
    { value: 'cherthala', displayValue: 'Cherthala' },
    { value: 'chhapra', displayValue: 'Chhapra' },
    { value: 'chhatarpur', displayValue: 'Chhatarpur' },
    { value: 'chhindwara', displayValue: 'Chhindwara' },
    { value: 'chidambaram', displayValue: 'Chidambaram' },
    { value: 'chikhli', displayValue: 'Chikhli' },
    { value: 'chikkaballapur', displayValue: 'Chikkaballapur' },
    { value: 'chikkamagaluru', displayValue: 'Chikkamagaluru' },
    { value: 'chikmagalur', displayValue: 'Chikmagalur' },
    { value: 'chilakaluripet', displayValue: 'Chilakaluripet' },
    { value: 'chintamani', displayValue: 'Chintamani' },
    { value: 'chiplun', displayValue: 'Chiplun' },
    { value: 'chirala', displayValue: 'Chirala' },
    { value: 'chitradurga', displayValue: 'Chitradurga' },
    { value: 'chitrakoot', displayValue: 'Chitrakoot' },
    { value: 'chittaurgarh', displayValue: 'Chittaurgarh' },
    { value: 'chittoor', displayValue: 'Chittoor' },
    { value: 'chittorgarh', displayValue: 'Chittorgarh' },
    { value: 'chittur-tattamangalam', displayValue: 'Chittur-Tattamangalam' },
    { value: 'chomu', displayValue: 'Chomu' },
    { value: 'chopda', displayValue: 'Chopda' },
    { value: 'churu', displayValue: 'Churu' },
    { value: 'coimbatore', displayValue: 'Coimbatore' },
    { value: 'contai', displayValue: 'Contai' },
    { value: 'cooch_behar', displayValue: 'Cooch Behar' },
    { value: 'coonoor', displayValue: 'Coonoor' },
    { value: 'coorg', displayValue: 'Coorg' },
    { value: 'corbett_national_park', displayValue: 'Corbett National Park' },
    { value: 'cuddalore', displayValue: 'Cuddalore' },
    { value: 'cuttack', displayValue: 'Cuttack' },
    { value: 'dabhoi', displayValue: 'Dabhoi' },
    { value: 'dabhol', displayValue: 'Dabhol' },
    { value: 'dabhosa', displayValue: 'Dabhosa' },
    { value: 'dabra', displayValue: 'Dabra' },
    { value: 'dabwali', displayValue: 'Dabwali' },
    { value: 'dahanu', displayValue: 'Dahanu' },
    { value: 'dahod', displayValue: 'Dahod' },
    { value: 'dalhousie', displayValue: 'Dalhousie' },
    { value: 'daltonganj', displayValue: 'Daltonganj' },
    { value: 'daman', displayValue: 'Daman' },
    { value: 'damoh', displayValue: 'Damoh' },
    { value: 'dandeli', displayValue: 'Dandeli' },
    { value: 'dapoli', displayValue: 'Dapoli' },
    { value: 'darbhanga', displayValue: 'Darbhanga' },
    { value: 'darjeeling', displayValue: 'Darjeeling' },
    { value: 'daryapur', displayValue: 'Daryapur' },
    { value: 'datia', displayValue: 'Datia' },
    { value: 'daudnagar', displayValue: 'Daudnagar' },
    { value: 'daulatabad', displayValue: 'Daulatabad' },
    { value: 'daund', displayValue: 'Daund' },
    { value: 'dausa', displayValue: 'Dausa' },
    { value: 'davangere', displayValue: 'Davangere' },
    { value: 'davol', displayValue: 'Davol' },
    { value: 'deglur', displayValue: 'Deglur' },
    { value: 'dehradun', displayValue: 'Dehradun' },
    { value: 'dehri', displayValue: 'Dehri' },
    { value: 'delhi', displayValue: 'Delhi' },
    { value: 'deoghar', displayValue: 'Deoghar' },
    { value: 'deori', displayValue: 'Deori' },
    { value: 'deoria_city', displayValue: 'Deoria City' },
    { value: 'deoria', displayValue: 'Deoria' },
    { value: 'deorukh', displayValue: 'Deorukh' },
    { value: 'deshalpar', displayValue: 'Deshalpar' },
    { value: 'deulgaon_mahi', displayValue: 'Deulgaon Mahi' },
    { value: 'deulgaon_raja', displayValue: 'Deulgaon Raja' },
    { value: 'devpar', displayValue: 'Devpar' },
    { value: 'dewas', displayValue: 'Dewas' },
    { value: 'dhampur', displayValue: 'Dhampur' },
    { value: 'dhamtari', displayValue: 'Dhamtari' },
    { value: 'dhanbad', displayValue: 'Dhanbad' },
    { value: 'dhanera', displayValue: 'Dhanera' },
    { value: 'dhar', displayValue: 'Dhar' },
    { value: 'dharampur', displayValue: 'Dharampur' },
    { value: 'dharamshala', displayValue: 'Dharamshala' },
    { value: 'dharapuram', displayValue: 'Dharapuram' },
    { value: 'dharmapuri', displayValue: 'Dharmapuri' },
    { value: 'dharmavaram', displayValue: 'Dharmavaram' },
    { value: 'dharmshala', displayValue: 'Dharmshala' },
    { value: 'dharwad', displayValue: 'Dharwad' },
    { value: 'dhaulpur', displayValue: 'Dhaulpur' },
    { value: 'dhemaji', displayValue: 'Dhemaji' },
    { value: 'dhenkanal', displayValue: 'Dhenkanal' },
    { value: 'dhoki', displayValue: 'Dhoki' },
    { value: 'dholka', displayValue: 'Dholka' },
    { value: 'dholpur', displayValue: 'Dholpur' },
    { value: 'dhoraji', displayValue: 'Dhoraji' },
    { value: 'dhrangadhra', displayValue: 'Dhrangadhra' },
    { value: 'dhubri', displayValue: 'Dhubri' },
    { value: 'dhuburi', displayValue: 'Dhuburi' },
    { value: 'dhule', displayValue: 'Dhule' },
    { value: 'dhupguri', displayValue: 'Dhupguri' },
    { value: 'dhuri', displayValue: 'Dhuri' },
    { value: 'diamond_harbour', displayValue: 'Diamond Harbour' },
    { value: 'dibrugarh', displayValue: 'Dibrugarh' },
    { value: 'digha', displayValue: 'Digha' },
    { value: 'digras', displayValue: 'Digras' },
    { value: 'dinapur_nizamat', displayValue: 'Dinapur Nizamat' },
    { value: 'dinara', displayValue: 'Dinara' },
    { value: 'dindigul', displayValue: 'Dindigul' },
    { value: 'dispur', displayValue: 'Dispur' },
    { value: 'diu', displayValue: 'Diu' },
    { value: 'diva', displayValue: 'Diva' },
    { value: 'dive_agar', displayValue: 'Dive Agar' },
    { value: 'doda', displayValue: 'Doda' },
    { value: 'doddaballapura', displayValue: 'Doddaballapura' },
    { value: 'dongaon', displayValue: 'Dongaon' },
    { value: 'dooars', displayValue: 'Dooars' },
    { value: 'dowlaiswaram', displayValue: 'Dowlaiswaram' },
    {
        value: 'dr_ambedkar_nagar (mhow)',
        displayValue: 'Dr. Ambedkar Nagar (Mhow)',
    },
    { value: 'duliajan', displayValue: 'Duliajan' },
    { value: 'dum_dum', displayValue: 'Dum Dum' },
    { value: 'dumka', displayValue: 'Dumka' },
    { value: 'dunawa', displayValue: 'Dunawa' },
    { value: 'dungarpur', displayValue: 'Dungarpur' },
    { value: 'durg', displayValue: 'Durg' },
    { value: 'durgapur', displayValue: 'Durgapur' },
    { value: 'durshet', displayValue: 'Durshet' },
    { value: 'dwarka', displayValue: 'Dwarka' },
    { value: 'ellenabad', displayValue: 'Ellenabad' },
    { value: 'eluru', displayValue: 'Eluru' },
    { value: 'erandol', displayValue: 'Erandol' },
    { value: 'erattupetta', displayValue: 'Erattupetta' },
    { value: 'erichanatham', displayValue: 'Erichanatham' },
    { value: 'erode_bypass', displayValue: 'Erode Bypass' },
    { value: 'erode', displayValue: 'Erode' },
    { value: 'etah', displayValue: 'Etah' },
    { value: 'etawah', displayValue: 'Etawah' },
    { value: 'faizabad-ayodhya', displayValue: 'Faizabad-Ayodhya' },
    { value: 'faizabad', displayValue: 'Faizabad' },
    { value: 'faizpur', displayValue: 'Faizpur' },
    { value: 'faridabad', displayValue: 'Faridabad' },
    { value: 'faridkot', displayValue: 'Faridkot' },
    {
        value: 'farrukhabad / fatehgarh',
        displayValue: 'Farrukhabad / Fatehgarh',
    },
    { value: 'farrukhabad', displayValue: 'Farrukhabad' },
    { value: 'fatehabad', displayValue: 'Fatehabad' },
    { value: 'fatehgarhsahib', displayValue: 'FatehgarhSahib' },
    { value: 'fatehpur_sikri', displayValue: 'Fatehpur Sikri' },
    { value: 'fatehpur', displayValue: 'Fatehpur' },
    { value: 'fazilka', displayValue: 'Fazilka' },
    { value: 'firozabad', displayValue: 'Firozabad' },
    { value: 'firozpur_jhirka', displayValue: 'Firozpur Jhirka' },
    { value: 'firozpur', displayValue: 'Firozpur' },
    { value: 'gadag-betageri', displayValue: 'Gadag-Betageri' },
    { value: 'gadhakada', displayValue: 'Gadhakada' },
    { value: 'gadhinglaj', displayValue: 'Gadhinglaj' },
    { value: 'ganapatipule', displayValue: 'Ganapatipule' },
    { value: 'ganaur', displayValue: 'Ganaur' },
    { value: 'gandhidham', displayValue: 'Gandhidham' },
    { value: 'gandhinagar', displayValue: 'Gandhinagar' },
    { value: 'gangakhed', displayValue: 'Gangakhed' },
    { value: 'ganganagar', displayValue: 'Ganganagar' },
    { value: 'gangapur', displayValue: 'Gangapur' },
    { value: 'gangarampur', displayValue: 'Gangarampur' },
    { value: 'gangavaram', displayValue: 'Gangavaram' },
    { value: 'gangavathi', displayValue: 'Gangavathi' },
    { value: 'gangotri', displayValue: 'Gangotri' },
    { value: 'gangtok', displayValue: 'Gangtok' },
    { value: 'gargoti', displayValue: 'Gargoti' },
    { value: 'garhmukteshwar', displayValue: 'Garhmukteshwar' },
    { value: 'garhwal', displayValue: 'Garhwal' },
    { value: 'gauriganj', displayValue: 'Gauriganj' },
    { value: 'gaya', displayValue: 'Gaya' },
    { value: 'gharaunda', displayValue: 'Gharaunda' },
    { value: 'ghaziabad', displayValue: 'Ghaziabad' },
    { value: 'ghazipur', displayValue: 'Ghazipur' },
    { value: 'ghodbandar', displayValue: 'Ghodbandar' },
    { value: 'giridih', displayValue: 'Giridih' },
    { value: 'goa', displayValue: 'Goa' },
    { value: 'goalpara', displayValue: 'Goalpara' },
    { value: 'gobardanga', displayValue: 'Gobardanga' },
    { value: 'gobichettipalayam', displayValue: 'Gobichettipalayam' },
    { value: 'godda', displayValue: 'Godda' },
    { value: 'godhra', displayValue: 'Godhra' },
    { value: 'gohana', displayValue: 'Gohana' },
    { value: 'gokharna', displayValue: 'Gokharna' },
    { value: 'gola_gokarannath', displayValue: 'Gola Gokarannath' },
    { value: 'golaghat', displayValue: 'Golaghat' },
    { value: 'gonda', displayValue: 'Gonda' },
    { value: 'gondal', displayValue: 'Gondal' },
    { value: 'gondia', displayValue: 'Gondia' },
    { value: 'gopalganj', displayValue: 'Gopalganj' },
    { value: 'gorakhpur', displayValue: 'Gorakhpur' },
    { value: 'greater_noida', displayValue: 'Greater Noida' },
    { value: 'gudivada', displayValue: 'Gudivada' },
    { value: 'gudiyatham', displayValue: 'Gudiyatham' },
    { value: 'gudluru', displayValue: 'Gudluru' },
    { value: 'gudur', displayValue: 'Gudur' },
    { value: 'gullapur', displayValue: 'Gullapur' },
    { value: 'gulmarg', displayValue: 'Gulmarg' },
    { value: 'guna', displayValue: 'Guna' },
    { value: 'guntakal', displayValue: 'Guntakal' },
    { value: 'guntur', displayValue: 'Guntur' },
    { value: 'gurdaspur', displayValue: 'Gurdaspur' },
    { value: 'gurgaon', displayValue: 'Gurgaon' },
    { value: 'gurugram', displayValue: 'Gurugram' },
    { value: 'guruvayoor', displayValue: 'Guruvayoor' },
    {
        value: 'guwahati_airport_borjhar',
        displayValue: 'Guwahati Airport Borjhar',
    },
    { value: 'guwahati', displayValue: 'Guwahati' },
    { value: 'gwalior', displayValue: 'Gwalior' },
    { value: 'gyalshing', displayValue: 'Gyalshing' },
    { value: 'habra', displayValue: 'Habra' },
    { value: 'hajipur', displayValue: 'Hajipur' },
    { value: 'haldia', displayValue: 'Haldia' },
    { value: 'haldwani', displayValue: 'Haldwani' },
    { value: 'halebid', displayValue: 'Halebid' },
    { value: 'halisahar', displayValue: 'Halisahar' },
    { value: 'hamirpur', displayValue: 'Hamirpur' },
    { value: 'halol', displayValue: 'Halol' },
    { value: 'hampi-hospet', displayValue: 'Hampi-Hospet' },
    { value: 'hampi', displayValue: 'Hampi' },
    { value: 'hanagal', displayValue: 'Hanagal' },
    { value: 'hansi', displayValue: 'Hansi' },
    { value: 'hanumangarh_town', displayValue: 'Hanumangarh Town' },
    { value: 'hanumangarh', displayValue: 'Hanumangarh' },
    { value: 'haora', displayValue: 'Haora' },
    { value: 'hapur', displayValue: 'Hapur' },
    { value: 'harda', displayValue: 'Harda' },
    { value: 'hardoi', displayValue: 'Hardoi' },
    { value: 'haridwar', displayValue: 'Haridwar' },
    { value: 'harihar', displayValue: 'Harihar' },
    { value: 'harishi', displayValue: 'Harishi' },
    { value: 'hassan', displayValue: 'Hassan' },
    { value: 'hathras', displayValue: 'Hathras' },
    { value: 'hatta', displayValue: 'Hatta' },
    { value: 'haveri', displayValue: 'Haveri' },
    { value: 'hazaribag', displayValue: 'Hazaribag' },
    { value: 'hazaribagh', displayValue: 'Hazaribagh' },
    { value: 'himatnagar', displayValue: 'Himatnagar' },
    { value: 'hindaun', displayValue: 'Hindaun' },
    { value: 'hinganghat', displayValue: 'Hinganghat' },
    { value: 'hingoli', displayValue: 'Hingoli' },
    { value: 'hiriyur', displayValue: 'Hiriyur' },
    { value: 'hisar', displayValue: 'Hisar' },
    { value: 'hoshangabad', displayValue: 'Hoshangabad' },
    { value: 'hoshiarpur', displayValue: 'Hoshiarpur' },
    { value: 'hospet', displayValue: 'Hospet' },
    { value: 'hosur', displayValue: 'Hosur' },
    { value: 'hubballi-dharwad', displayValue: 'Hubballi-Dharwad' },
    { value: 'hubli', displayValue: 'Hubli' },
    { value: 'hugli', displayValue: 'Hugli' },
    { value: 'humnabad', displayValue: 'Humnabad' },
    { value: 'hyderabad', displayValue: 'Hyderabad' },
    { value: 'ichalkaranji', displayValue: 'Ichalkaranji' },
    { value: 'idukki', displayValue: 'Idukki' },
    { value: 'igatpuri', displayValue: 'Igatpuri' },
    { value: 'imphal', displayValue: 'Imphal' },
    { value: 'indore', displayValue: 'Indore' },
    { value: 'ingraj_bazar', displayValue: 'Ingraj Bazar' },
    { value: 'islampur', displayValue: 'Islampur' },
    { value: 'itanagar', displayValue: 'Itanagar' },
    { value: 'itarsi', displayValue: 'Itarsi' },
    { value: 'jabalpur', displayValue: 'Jabalpur' },
    { value: 'jagdalpur', displayValue: 'Jagdalpur' },
    { value: 'jagraon', displayValue: 'Jagraon' },
    { value: 'jagtial', displayValue: 'Jagtial' },
    { value: 'jaipur', displayValue: 'Jaipur' },
    { value: 'jaisalmer', displayValue: 'Jaisalmer' },
    { value: 'jaisinghpur', displayValue: 'Jaisinghpur' },
    { value: 'jalalabad', displayValue: 'Jalalabad' },
    { value: 'jalandhar', displayValue: 'Jalandhar' },
    { value: 'jalaun', displayValue: 'Jalaun' },
    { value: 'jalgaon', displayValue: 'Jalgaon' },
    { value: 'jalna', displayValue: 'Jalna' },
    { value: 'jalor', displayValue: 'Jalor' },
    { value: 'jalpaiguri', displayValue: 'Jalpaiguri' },
    { value: 'jamalpur', displayValue: 'Jamalpur' },
    { value: 'jambugodha', displayValue: 'Jambugodha' },
    { value: 'jamkhandi', displayValue: 'Jamkhandi' },
    { value: 'jammu', displayValue: 'Jammu' },
    { value: 'jamnagar', displayValue: 'Jamnagar' },
    { value: 'jamshedpur', displayValue: 'Jamshedpur' },
    { value: 'jamui', displayValue: 'Jamui' },
    { value: 'janjgir', displayValue: 'Janjgir' },
    { value: 'jaora', displayValue: 'Jaora' },
    { value: 'jaunpur', displayValue: 'Jaunpur' },
    { value: 'jawhar', displayValue: 'Jawhar' },
    { value: 'jayankondam', displayValue: 'Jayankondam' },
    { value: 'jaysingpur', displayValue: 'Jaysingpur' },
    { value: 'jedarpalayam', displayValue: 'Jedarpalayam' },
    { value: 'jehanabad', displayValue: 'Jehanabad' },
    { value: 'jetpur', displayValue: 'Jetpur' },
    { value: 'jhabua', displayValue: 'Jhabua' },
    { value: 'jhajjar', displayValue: 'Jhajjar' },
    { value: 'jhalawar', displayValue: 'Jhalawar' },
    { value: 'jhansi', displayValue: 'Jhansi' },
    { value: 'jhargram', displayValue: 'Jhargram' },
    { value: 'jharia', displayValue: 'Jharia' },
    { value: 'jharsuguda', displayValue: 'Jharsuguda' },
    { value: 'jhumri_telaiya', displayValue: 'Jhumri Telaiya' },
    { value: 'jhunjhunu', displayValue: 'Jhunjhunu' },
    { value: 'jiaganj', displayValue: 'Jiaganj' },
    { value: 'jind', displayValue: 'Jind' },
    { value: 'jindal', displayValue: 'Jindal' },
    { value: 'jodhpur', displayValue: 'Jodhpur' },
    { value: 'jojawar', displayValue: 'Jojawar' },
    { value: 'jorhat', displayValue: 'Jorhat' },
    { value: 'junagadh', displayValue: 'Junagadh' },
    { value: 'k.paramathi', displayValue: 'K.Paramathi' },
    { value: 'kabini', displayValue: 'Kabini' },
    { value: 'kadapa', displayValue: 'Kadapa' },
    { value: 'kadayanallur', displayValue: 'Kadayanallur' },
    { value: 'kadepur', displayValue: 'Kadepur' },
    { value: 'kadiri', displayValue: 'Kadiri' },
    { value: 'kadur', displayValue: 'Kadur' },
    { value: 'kagal', displayValue: 'Kagal' },
    { value: 'kaithal', displayValue: 'Kaithal' },
    { value: 'kakinada', displayValue: 'Kakinada' },
    { value: 'kalaburagi', displayValue: 'Kalaburagi' },
    { value: 'kalamb', displayValue: 'Kalamb' },
    { value: 'kalimpong', displayValue: 'Kalimpong' },
    { value: 'kallakkurichi', displayValue: 'Kallakkurichi' },
    { value: 'kallur', displayValue: 'Kallur' },
    { value: 'kalna', displayValue: 'Kalna' },
    { value: 'kalol', displayValue: 'Kalol' },
    { value: 'kalpetta', displayValue: 'Kalpetta' },
    { value: 'kalyan', displayValue: 'Kalyan' },
    { value: 'kamareddy', displayValue: 'Kamareddy' },
    { value: 'kamarhati', displayValue: 'Kamarhati' },
    { value: 'kanatal', displayValue: 'Kanatal' },
    { value: 'kanchipuram', displayValue: 'Kanchipuram' },
    { value: 'kanchrapara', displayValue: 'Kanchrapara' },
    { value: 'kandhla', displayValue: 'Kandhla' },
    { value: 'kandi', displayValue: 'Kandi' },
    { value: 'kandla', displayValue: 'Kandla' },
    { value: 'kandukur', displayValue: 'Kandukur' },
    { value: 'kangeyam', displayValue: 'Kangeyam' },
    { value: 'kangra', displayValue: 'Kangra' },
    { value: 'kanha', displayValue: 'Kanha' },
    { value: 'kanhangad', displayValue: 'Kanhangad' },
    { value: 'kanker', displayValue: 'Kanker' },
    { value: 'kannauj', displayValue: 'Kannauj' },
    { value: 'kanniyakumari', displayValue: 'Kanniyakumari' },
    { value: 'kannur', displayValue: 'Kannur' },
    { value: 'kanpur', displayValue: 'Kanpur' },
    { value: 'kansabel', displayValue: 'Kansabel' },
    { value: 'kanyakumari', displayValue: 'Kanyakumari' },
    { value: 'kapadvanj', displayValue: 'Kapadvanj' },
    { value: 'kapu', displayValue: 'Kapu' },
    { value: 'kapurthala', displayValue: 'Kapurthala' },
    { value: 'karad', displayValue: 'Karad' },
    { value: 'karaikal', displayValue: 'Karaikal' },
    { value: 'karaikudi', displayValue: 'Karaikudi' },
    { value: 'karanja', displayValue: 'Karanja' },
    { value: 'karauli', displayValue: 'Karauli' },
    { value: 'kargil', displayValue: 'Kargil' },
    { value: 'karimganj', displayValue: 'Karimganj' },
    { value: 'karimnagar', displayValue: 'Karimnagar' },
    { value: 'karjat', displayValue: 'Karjat' },
    { value: 'karli', displayValue: 'Karli' },
    { value: 'karnal', displayValue: 'Karnal' },
    { value: 'karur_bypass', displayValue: 'Karur Bypass' },
    { value: 'karur', displayValue: 'Karur' },
    { value: 'karwar', displayValue: 'Karwar' },
    { value: 'kasal', displayValue: 'Kasal' },
    { value: 'kasargod', displayValue: 'Kasargod' },
    { value: 'kasauli', displayValue: 'Kasauli' },
    { value: 'kasganj', displayValue: 'Kasganj' },
    { value: 'kashid', displayValue: 'Kashid' },
    { value: 'kashipur', displayValue: 'Kashipur' },
    { value: 'katapady', displayValue: 'Katapady' },
    { value: 'kathua', displayValue: 'Kathua' },
    { value: 'katihar', displayValue: 'Katihar' },
    { value: 'katni', displayValue: 'Katni' },
    { value: 'katol', displayValue: 'Katol' },
    { value: 'katpadi', displayValue: 'Katpadi' },
    { value: 'katra', displayValue: 'Katra' },
    { value: 'kausani', displayValue: 'Kausani' },
    { value: 'kavali', displayValue: 'Kavali' },
    { value: 'kayamkulam', displayValue: 'Kayamkulam' },
    { value: 'kaza', displayValue: 'Kaza' },
    { value: 'kaziranga', displayValue: 'Kaziranga' },
    { value: 'kedarnath', displayValue: 'Kedarnath' },
    { value: 'kendrapara', displayValue: 'Kendrapara' },
    { value: 'kendujhar', displayValue: 'Kendujhar' },
    { value: 'keonjhar', displayValue: 'Keonjhar' },
    { value: 'keshod', displayValue: 'Keshod' },
    { value: 'kevadia', displayValue: 'Kevadia' },
    { value: 'khagaria', displayValue: 'Khagaria' },
    { value: 'khajjiar', displayValue: 'Khajjiar' },
    { value: 'khajuraho', displayValue: 'Khajuraho' },
    { value: 'khalilabad', displayValue: 'Khalilabad' },
    { value: 'khambhalia', displayValue: 'Khambhalia' },
    { value: 'khambhat', displayValue: 'Khambhat' },
    { value: 'khammam', displayValue: 'Khammam' },
    { value: 'khanapur', displayValue: 'Khanapur' },
    { value: 'khandala', displayValue: 'Khandala' },
    { value: 'khandwa', displayValue: 'Khandwa' },
    { value: 'khanna', displayValue: 'Khanna' },
    { value: 'kharagpur', displayValue: 'Kharagpur' },
    { value: 'khargapur', displayValue: 'Khargapur' },
    { value: 'khargone', displayValue: 'Khargone' },
    { value: 'kheda', displayValue: 'Kheda' },
    { value: 'khetri', displayValue: 'Khetri' },
    { value: 'khilchipur', displayValue: 'Khilchipur' },
    { value: 'khimana', displayValue: 'Khimana' },
    { value: 'khimsar', displayValue: 'Khimsar' },
    { value: 'khurja', displayValue: 'Khurja' },
    { value: 'kichha', displayValue: 'Kichha' },
    { value: 'kinnaur', displayValue: 'Kinnaur' },
    { value: 'kishanganj', displayValue: 'Kishanganj' },
    { value: 'kishangarh', displayValue: 'Kishangarh' },
    { value: 'kochi', displayValue: 'Kochi' },
    { value: 'kochin', displayValue: 'Kochin' },
    { value: 'kodaikanal', displayValue: 'Kodaikanal' },
    { value: 'kodinar', displayValue: 'Kodinar' },
    { value: 'kodungallur', displayValue: 'Kodungallur' },
    { value: 'kohima', displayValue: 'Kohima' },
    { value: 'kolar', displayValue: 'Kolar' },
    { value: 'kolhapur', displayValue: 'Kolhapur' },
    { value: 'kolhar', displayValue: 'Kolhar' },
    { value: 'kolkata', displayValue: 'Kolkata' },
    { value: 'kollam', displayValue: 'Kollam' },
    { value: 'kollipara', displayValue: 'Kollipara' },
    { value: 'kommuru', displayValue: 'Kommuru' },
    { value: 'konark', displayValue: 'Konark' },
    { value: 'kopargaon', displayValue: 'Kopargaon' },
    { value: 'koppal', displayValue: 'Koppal' },
    { value: 'koraput', displayValue: 'Koraput' },
    { value: 'korba', displayValue: 'Korba' },
    { value: 'kota', displayValue: 'Kota' },
    { value: 'kotagiri', displayValue: 'Kotagiri' },
    { value: 'kothagudem', displayValue: 'Kothagudem' },
    { value: 'kotkapura', displayValue: 'KotKapura' },
    { value: 'kotputli', displayValue: 'Kotputli' },
    { value: 'kottayam', displayValue: 'Kottayam' },
    { value: 'kotturu', displayValue: 'Kotturu' },
    { value: 'kovalam', displayValue: 'Kovalam' },
    { value: 'kovilpatti', displayValue: 'Kovilpatti' },
    { value: 'kovur', displayValue: 'Kovur' },
    { value: 'kozhikode', displayValue: 'Kozhikode' },
    { value: 'krishnagiri', displayValue: 'Krishnagiri' },
    { value: 'krishnanagar', displayValue: 'Krishnanagar' },
    { value: 'kuchaman', displayValue: 'Kuchaman' },
    { value: 'kufri', displayValue: 'Kufri' },
    { value: 'kulathur', displayValue: 'Kulathur' },
    { value: 'kullu', displayValue: 'Kullu' },
    { value: 'kumarakom', displayValue: 'Kumarakom' },
    { value: 'kumbakonam', displayValue: 'Kumbakonam' },
    { value: 'kumbalgarh', displayValue: 'Kumbalgarh' },
    { value: 'kumily', displayValue: 'Kumily' },
    { value: 'kumta', displayValue: 'Kumta' },
    { value: 'kunigal', displayValue: 'Kunigal' },
    { value: 'kunnamkulam', displayValue: 'Kunnamkulam' },
    { value: 'kurha', displayValue: 'Kurha' },
    { value: 'kurnool', displayValue: 'Kurnool' },
    { value: 'kurseong', displayValue: 'Kurseong' },
    { value: 'kurukshetra', displayValue: 'Kurukshetra' },
    { value: 'kushinagar', displayValue: 'Kushinagar' },
    { value: 'kushtagi', displayValue: 'Kushtagi' },
    { value: 'lachung', displayValue: 'Lachung' },
    { value: 'lakhanpur', displayValue: 'Lakhanpur' },
    { value: 'lakhimpur', displayValue: 'Lakhimpur' },
    { value: 'lakhisarai', displayValue: 'Lakhisarai' },
    { value: 'lakshadweep', displayValue: 'Lakshadweep' },
    { value: 'lalitpur', displayValue: 'Lalitpur' },
    { value: 'latur', displayValue: 'Latur' },
    { value: 'leh', displayValue: 'Leh' },
    { value: 'likhala', displayValue: 'Likhala' },
    { value: 'lodhava', displayValue: 'Lodhava' },
    { value: 'lohara', displayValue: 'Lohara' },
    { value: 'lonavala', displayValue: 'Lonavala' },
    { value: 'loni', displayValue: 'Loni' },
    { value: 'lothal', displayValue: 'Lothal' },
    { value: 'lucknow', displayValue: 'Lucknow' },
    { value: 'ludhiana', displayValue: 'Ludhiana' },
    { value: 'lumbini', displayValue: 'Lumbini' },
    { value: 'lunglei', displayValue: 'Lunglei' },
    { value: 'machilipatnam', displayValue: 'Machilipatnam' },
    { value: 'madanapalle', displayValue: 'Madanapalle' },
    { value: 'madgaon', displayValue: 'Madgaon' },
    { value: 'madhepura', displayValue: 'Madhepura' },
    { value: 'madhubani', displayValue: 'Madhubani' },
    { value: 'madikeri', displayValue: 'Madikeri' },
    { value: 'madurai', displayValue: 'Madurai' },
    { value: 'mahabaleshwar', displayValue: 'Mahabaleshwar' },
    { value: 'mahabalipuram', displayValue: 'Mahabalipuram' },
    { value: 'maharajganj', displayValue: 'Maharajganj' },
    { value: 'mahasamund', displayValue: 'Mahasamund' },
    { value: 'mahbubnagar', displayValue: 'Mahbubnagar' },
    { value: 'mahe', displayValue: 'Mahe' },
    { value: 'mahemdavad', displayValue: 'Mahemdavad' },
    { value: 'mahesana', displayValue: 'Mahesana' },
    { value: 'maheshwar', displayValue: 'Maheshwar' },
    { value: 'mahoba', displayValue: 'Mahoba' },
    { value: 'mahuva', displayValue: 'Mahuva' },
    { value: 'mainpuri', displayValue: 'Mainpuri' },
    { value: 'majalgaon', displayValue: 'Majalgaon' },
    { value: 'malappuram', displayValue: 'Malappuram' },
    { value: 'malavalli', displayValue: 'Malavalli' },
    { value: 'malda', displayValue: 'Malda' },
    { value: 'malegaon', displayValue: 'Malegaon' },
    { value: 'malerkotla', displayValue: 'Malerkotla' },
    { value: 'malkapur', displayValue: 'Malkapur' },
    { value: 'mallapura', displayValue: 'Mallapura' },
    { value: 'malout', displayValue: 'Malout' },
    { value: 'malpe', displayValue: 'Malpe' },
    { value: 'malshej_ghat', displayValue: 'Malshej Ghat' },
    { value: 'malvan', displayValue: 'Malvan' },
    { value: 'mamallapuram', displayValue: 'Mamallapuram' },
    { value: 'manachanallur', displayValue: 'Manachanallur' },
    { value: 'manali', displayValue: 'Manali' },
    { value: 'mancherial', displayValue: 'Mancherial' },
    { value: 'mandapeta', displayValue: 'Mandapeta' },
    { value: 'mandavi', displayValue: 'Mandavi' },
    { value: 'mandawa', displayValue: 'Mandawa' },
    { value: 'mandi_dabwali', displayValue: 'Mandi Dabwali' },
    { value: 'mandi', displayValue: 'Mandi' },
    { value: 'mandideep', displayValue: 'Mandideep' },
    { value: 'mandla', displayValue: 'Mandla' },
    { value: 'mandormoni', displayValue: 'Mandormoni' },
    { value: 'mandsaur', displayValue: 'Mandsaur' },
    { value: 'mandvi', displayValue: 'Mandvi' },
    { value: 'mandya', displayValue: 'Mandya' },
    { value: 'manesar', displayValue: 'Manesar' },
    { value: 'mangalore', displayValue: 'Mangalore' },
    { value: 'mangaluru', displayValue: 'Mangaluru' },
    { value: 'mangan', displayValue: 'Mangan' },
    { value: 'manikaran', displayValue: 'Manikaran' },
    { value: 'manjari', displayValue: 'Manjari' },
    { value: 'manjeri', displayValue: 'Manjeri' },
    { value: 'manmad', displayValue: 'Manmad' },
    { value: 'mansa', displayValue: 'Mansa' },
    { value: 'mararri', displayValue: 'Mararri' },
    { value: 'marchula', displayValue: 'Marchula' },
    { value: 'marlapadu', displayValue: 'Marlapadu' },
    { value: 'masaurhi', displayValue: 'Masaurhi' },
    { value: 'matheran', displayValue: 'Matheran' },
    { value: 'mathura', displayValue: 'Mathura' },
    { value: 'mattancheri', displayValue: 'Mattancheri' },
    { value: 'mattannur', displayValue: 'Mattannur' },
    { value: 'mau', displayValue: 'Mau' },
    { value: 'mauranipur', displayValue: 'Mauranipur' },
    { value: 'mayiladuthurai', displayValue: 'Mayiladuthurai' },
    { value: 'mcleodganj', displayValue: 'Mcleodganj' },
    { value: 'meerut', displayValue: 'Meerut' },
    { value: 'meghpar', displayValue: 'Meghpar' },
    { value: 'mehsana', displayValue: 'Mehsana' },
    { value: 'merta', displayValue: 'Merta' },
    { value: 'mettupalayam', displayValue: 'Mettupalayam' },
    { value: 'mettur', displayValue: 'Mettur' },
    { value: 'midnapore', displayValue: 'Midnapore' },
    { value: 'midnapur', displayValue: 'Midnapur' },
    { value: 'miryalaguda', displayValue: 'Miryalaguda' },
    { value: 'mirzapur-vindhyachal', displayValue: 'Mirzapur-Vindhyachal' },
    { value: 'mirzapur', displayValue: 'Mirzapur' },
    { value: 'modasa', displayValue: 'Modasa' },
    { value: 'modinagar', displayValue: 'Modinagar' },
    { value: 'moga', displayValue: 'Moga' },
    { value: 'mohali', displayValue: 'Mohali' },
    { value: 'mohammadpur', displayValue: 'Mohammadpur' },
    { value: 'mon', displayValue: 'Mon' },
    { value: 'moodbidri', displayValue: 'Moodbidri' },
    { value: 'moradabad', displayValue: 'Moradabad' },
    { value: 'morbi', displayValue: 'Morbi' },
    { value: 'morena', displayValue: 'Morena' },
    { value: 'mota_zinzuda', displayValue: 'Mota Zinzuda' },
    { value: 'motihari', displayValue: 'Motihari' },
    { value: 'mount_abu', displayValue: 'Mount Abu' },
    { value: 'mughalsarai', displayValue: 'Mughalsarai' },
    { value: 'mukhiguda', displayValue: 'Mukhiguda' },
    { value: 'mukteshwar', displayValue: 'Mukteshwar' },
    { value: 'muktsar', displayValue: 'Muktsar' },
    { value: 'mullana', displayValue: 'Mullana' },
    { value: 'mumanvas', displayValue: 'Mumanvas' },
    { value: 'mumbai', displayValue: 'Mumbai' },
    { value: 'mundra', displayValue: 'Mundra' },
    { value: 'munger', displayValue: 'Munger' },
    { value: 'munnar', displayValue: 'Munnar' },
    { value: 'murdeshwar', displayValue: 'Murdeshwar' },
    { value: 'murshidabad', displayValue: 'Murshidabad' },
    { value: 'murud_janjira', displayValue: 'Murud Janjira' },
    { value: 'murwara', displayValue: 'Murwara' },
    { value: 'musiri', displayValue: 'Musiri' },
    { value: 'mussoorie', displayValue: 'Mussoorie' },
    { value: 'muthur', displayValue: 'Muthur' },
    { value: 'muvattupuzha', displayValue: 'Muvattupuzha' },
    { value: 'muzaffarnagar', displayValue: 'Muzaffarnagar' },
    { value: 'muzaffarpur', displayValue: 'Muzaffarpur' },
    { value: 'mysore', displayValue: 'Mysore' },
    { value: 'mysuru', displayValue: 'Mysuru' },
    { value: 'nabadwip', displayValue: 'Nabadwip' },
    { value: 'nabha', displayValue: 'Nabha' },
    { value: 'nadiad', displayValue: 'Nadiad' },
    { value: 'nadukani', displayValue: 'Nadukani' },
    { value: 'nagaon', displayValue: 'Nagaon' },
    { value: 'nagapattinam', displayValue: 'Nagapattinam' },
    { value: 'nagappattinam', displayValue: 'Nagappattinam' },
    { value: 'nagara', displayValue: 'Nagara' },
    { value: 'nagarhole', displayValue: 'Nagarhole' },
    { value: 'nagarjunakoṇḍa', displayValue: 'Nagarjunakoṇḍa' },
    { value: 'nagaur_fort', displayValue: 'Nagaur Fort' },
    { value: 'nagaur', displayValue: 'Nagaur' },
    { value: 'nagda', displayValue: 'Nagda' },
    { value: 'nagel', displayValue: 'Nagel' },
    { value: 'nagercoil', displayValue: 'Nagercoil' },
    { value: 'nagina', displayValue: 'Nagina' },
    { value: 'nagoor', displayValue: 'Nagoor' },
    { value: 'nagore', displayValue: 'Nagore' },
    { value: 'nagothane', displayValue: 'Nagothane' },
    { value: 'nagpur', displayValue: 'Nagpur' },
    { value: 'nahan', displayValue: 'Nahan' },
    { value: 'naigaon', displayValue: 'Naigaon' },
    { value: 'nainital', displayValue: 'Nainital' },
    { value: 'nakodar', displayValue: 'Nakodar' },
    { value: 'nalbari', displayValue: 'Nalbari' },
    { value: 'naldhera', displayValue: 'Naldhera' },
    { value: 'nalegaon', displayValue: 'Nalegaon' },
    { value: 'nalgonda', displayValue: 'Nalgonda' },
    { value: 'namakkal', displayValue: 'Namakkal' },
    { value: 'nanded', displayValue: 'Nanded' },
    { value: 'nandgaon', displayValue: 'Nandgaon' },
    { value: 'nandivelugu', displayValue: 'Nandivelugu' },
    { value: 'nandura', displayValue: 'Nandura' },
    { value: 'nandyal', displayValue: 'Nandyal' },
    { value: 'nangal', displayValue: 'Nangal' },
    { value: 'nanjangud', displayValue: 'Nanjangud' },
    { value: 'nanpara', displayValue: 'Nanpara' },
    { value: 'napne', displayValue: 'Napne' },
    { value: 'narasaraopet', displayValue: 'Narasaraopet' },
    { value: 'narayanpur', displayValue: 'Narayanpur' },
    { value: 'narnaul', displayValue: 'Narnaul' },
    { value: 'narsapur', displayValue: 'Narsapur' },
    { value: 'narsapuram', displayValue: 'Narsapuram' },
    { value: 'narsi', displayValue: 'Narsi' },
    { value: 'narsimhapur', displayValue: 'Narsimhapur' },
    { value: 'narsinghgarh', displayValue: 'Narsinghgarh' },
    { value: 'narsinghpur', displayValue: 'Narsinghpur' },
    { value: 'narwana', displayValue: 'Narwana' },
    { value: 'narwar', displayValue: 'Narwar' },
    { value: 'nashik', displayValue: 'Nashik' },
    { value: 'nasik', displayValue: 'Nasik' },
    { value: 'nathdwara', displayValue: 'Nathdwara' },
    { value: 'naugachhia', displayValue: 'Naugachhia' },
    { value: 'navagam', displayValue: 'Navagam' },
    { value: 'navi_mumbai', displayValue: 'Navi Mumbai' },
    { value: 'navsari', displayValue: 'Navsari' },
    { value: 'nawada', displayValue: 'Nawada' },
    { value: 'nawalgarh', displayValue: 'Nawalgarh' },
    { value: 'nawanshahr', displayValue: 'Nawanshahr' },
    { value: 'neemuch', displayValue: 'Neemuch' },
    { value: 'nelamangala', displayValue: 'Nelamangala' },
    { value: 'ner', displayValue: 'Ner' },
    { value: 'neral', displayValue: 'Neral' },
    { value: 'new_delhi', displayValue: 'New Delhi' },
    { value: 'neyveli', displayValue: 'Neyveli' },
    { value: 'nilgiri', displayValue: 'Nilgiri' },
    { value: 'nirmal', displayValue: 'Nirmal' },
    { value: 'nitk_surathkal', displayValue: 'Nitk Surathkal' },
    { value: 'nitte', displayValue: 'Nitte' },
    { value: 'nizamabad', displayValue: 'Nizamabad' },
    { value: 'nizampur', displayValue: 'Nizampur' },
    { value: 'noida', displayValue: 'Noida' },
    { value: 'north_lakhimpur', displayValue: 'North Lakhimpur' },
    { value: 'nowgong', displayValue: 'Nowgong' },
    { value: 'nuzividu', displayValue: 'Nuzividu' },
    { value: 'okha', displayValue: 'Okha' },
    { value: 'omalur', displayValue: 'Omalur' },
    { value: 'ongole', displayValue: 'Ongole' },
    { value: 'ooty', displayValue: 'Ooty' },
    { value: 'orai', displayValue: 'Orai' },
    { value: 'orchha', displayValue: 'Orchha' },
    { value: 'osian', displayValue: 'Osian' },
    { value: 'osmanabad', displayValue: 'Osmanabad' },
    { value: 'pachmarhi', displayValue: 'Pachmarhi' },
    { value: 'pachora', displayValue: 'Pachora' },
    { value: 'pachore', displayValue: 'Pachore' },
    { value: 'padra', displayValue: 'Padra' },
    { value: 'padrauna', displayValue: 'Padrauna' },
    { value: 'pahalgam', displayValue: 'Pahalgam' },
    { value: 'pahur', displayValue: 'Pahur' },
    { value: 'paithan', displayValue: 'Paithan' },
    { value: 'pakur', displayValue: 'Pakur' },
    { value: 'palakkad', displayValue: 'Palakkad' },
    { value: 'palakollu', displayValue: 'Palakollu' },
    { value: 'palam', displayValue: 'Palam' },
    { value: 'palampur', displayValue: 'Palampur' },
    { value: 'palani', displayValue: 'Palani' },
    { value: 'palanpur', displayValue: 'Palanpur' },
    { value: 'palashi', displayValue: 'Palashi' },
    { value: 'palayamkottai', displayValue: 'Palayamkottai' },
    { value: 'pali', displayValue: 'Pali' },
    { value: 'palitana', displayValue: 'Palitana' },
    { value: 'palladam', displayValue: 'Palladam' },
    { value: 'pallakad', displayValue: 'Pallakad' },
    { value: 'palwal', displayValue: 'Palwal' },
    { value: 'pamarru', displayValue: 'Pamarru' },
    { value: 'pamban', displayValue: 'Pamban' },
    { value: 'pamuru', displayValue: 'Pamuru' },
    { value: 'panaji', displayValue: 'Panaji' },
    { value: 'panchgani', displayValue: 'Panchgani' },
    { value: 'panchkula', displayValue: 'Panchkula' },
    { value: 'pandamangalam', displayValue: 'Pandamangalam' },
    { value: 'pandharpur', displayValue: 'Pandharpur' },
    { value: 'paneli moti', displayValue: 'Paneli Moti' },
    { value: 'panhala', displayValue: 'Panhala' },
    { value: 'panihati', displayValue: 'Panihati' },
    { value: 'panipat', displayValue: 'Panipat' },
    { value: 'panna', displayValue: 'Panna' },
    { value: 'panoor', displayValue: 'Panoor' },
    { value: 'panskura', displayValue: 'Panskura' },
    { value: 'pantnagar', displayValue: 'Pantnagar' },
    { value: 'panvel', displayValue: 'Panvel' },
    { value: 'paradip', displayValue: 'Paradip' },
    { value: 'paramakudi', displayValue: 'Paramakudi' },
    { value: 'paramankurichi', displayValue: 'Paramankurichi' },
    { value: 'parbhani', displayValue: 'Parbhani' },
    { value: 'parli_vaijnath', displayValue: 'Parli Vaijnath' },
    { value: 'parola', displayValue: 'Parola' },
    { value: 'partapgarh', displayValue: 'Partapgarh' },
    { value: 'parwanoo', displayValue: 'Parwanoo' },
    { value: 'patan', displayValue: 'Patan' },
    { value: 'pathanamthitta', displayValue: 'Pathanamthitta' },
    { value: 'pathankot', displayValue: 'Pathankot' },
    { value: 'pathri', displayValue: 'Pathri' },
    { value: 'patiala', displayValue: 'Patiala' },
    { value: 'patna', displayValue: 'Patna' },
    { value: 'patnitop', displayValue: 'Patnitop' },
    { value: 'patratu', displayValue: 'Patratu' },
    { value: 'pattambi', displayValue: 'Pattambi' },
    { value: 'pattukkottai', displayValue: 'Pattukkottai' },
    { value: 'pattukottai', displayValue: 'Pattukottai' },
    { value: 'patur', displayValue: 'Patur' },
    { value: 'pavagada', displayValue: 'Pavagada' },
    { value: 'payyannur', displayValue: 'Payyannur' },
    { value: 'pehowa', displayValue: 'Pehowa' },
    { value: 'pelling', displayValue: 'Pelling' },
    { value: 'pen', displayValue: 'Pen' },
    { value: 'pench', displayValue: 'Pench' },
    { value: 'peraiyur', displayValue: 'Peraiyur' },
    { value: 'perambalur', displayValue: 'Perambalur' },
    { value: 'perinthalmanna', displayValue: 'Perinthalmanna' },
    { value: 'periyakulam', displayValue: 'Periyakulam' },
    { value: 'peth_naka', displayValue: 'Peth Naka' },
    { value: 'petlad', displayValue: 'Petlad' },
    { value: 'phagwara', displayValue: 'Phagwara' },
    { value: 'phalodi', displayValue: 'Phalodi' },
    { value: 'phaltan', displayValue: 'Phaltan' },
    { value: 'phek', displayValue: 'Phek' },
    { value: 'phillaur', displayValue: 'Phillaur' },
    { value: 'phulabani', displayValue: 'Phulabani' },
    { value: 'phulia', displayValue: 'Phulia' },
    { value: 'phusro', displayValue: 'Phusro' },
    { value: 'pilani', displayValue: 'Pilani' },
    { value: 'pilibhit', displayValue: 'Pilibhit' },
    { value: 'pinjore', displayValue: 'Pinjore' },
    { value: 'pippara', displayValue: 'Pippara' },
    { value: 'pithampur', displayValue: 'Pithampur' },
    { value: 'pithavadi', displayValue: 'Pithavadi' },
    { value: 'pithoragarh', displayValue: 'Pithoragarh' },
    { value: 'piyava', displayValue: 'Piyava' },
    { value: 'pollachi', displayValue: 'Pollachi' },
    { value: 'polur', displayValue: 'Polur' },
    { value: 'pondicherry', displayValue: 'Pondicherry' },
    { value: 'ponnani', displayValue: 'Ponnani' },
    { value: 'ponta_sahib', displayValue: 'Ponta Sahib' },
    { value: 'pooparai', displayValue: 'Pooparai' },
    { value: 'poovar', displayValue: 'Poovar' },
    { value: 'porbandar', displayValue: 'Porbandar' },
    { value: 'port_blair', displayValue: 'Port Blair' },
    { value: 'poshina', displayValue: 'Poshina' },
    { value: 'pragpur', displayValue: 'Pragpur' },
    { value: 'pratapgarh', displayValue: 'Pratapgarh' },
    { value: 'prayagraj', displayValue: 'Prayagraj' },
    { value: 'proddatur', displayValue: 'Proddatur' },
    { value: 'puducherry', displayValue: 'Puducherry' },
    { value: 'pudukkottai', displayValue: 'Pudukkottai' },
    { value: 'pudukottai', displayValue: 'Pudukottai' },
    { value: 'puliangudi', displayValue: 'Puliangudi' },
    { value: 'pullambadi', displayValue: 'Pullambadi' },
    { value: 'punch', displayValue: 'Punch' },
    { value: 'pune', displayValue: 'Pune' },
    { value: 'puranpur', displayValue: 'Puranpur' },
    { value: 'puri', displayValue: 'Puri' },
    { value: 'purnea', displayValue: 'Purnea' },
    { value: 'purnia', displayValue: 'Purnia' },
    { value: 'purulia', displayValue: 'Purulia' },
    { value: 'pusa', displayValue: 'Pusa' },
    { value: 'pusad', displayValue: 'Pusad' },
    { value: 'pushkar', displayValue: 'Pushkar' },
    { value: 'puskhar', displayValue: 'Puskhar' },
    { value: 'puttaparthi', displayValue: 'Puttaparthi' },
    { value: 'puttur_ap', displayValue: 'Puttur AP' },
    { value: 'puttur', displayValue: 'Puttur' },
    { value: 'quilandy', displayValue: 'Quilandy' },
    { value: 'rae_bareli', displayValue: 'Rae Bareli' },
    { value: 'raebareli', displayValue: 'Raebareli' },
    { value: 'rai_bareilly', displayValue: 'Rai Bareilly' },
    { value: 'raichak', displayValue: 'Raichak' },
    { value: 'raichur', displayValue: 'Raichur' },
    { value: 'raiganj', displayValue: 'Raiganj' },
    { value: 'raigarh', displayValue: 'Raigarh' },
    { value: 'raipur', displayValue: 'Raipur' },
    { value: 'raisen', displayValue: 'Raisen' },
    { value: 'rajahmundry', displayValue: 'Rajahmundry' },
    { value: 'rajanagaram', displayValue: 'Rajanagaram' },
    { value: 'rajapalayam', displayValue: 'Rajapalayam' },
    { value: 'rajgarh', displayValue: 'Rajgarh' },
    { value: 'rajgir', displayValue: 'Rajgir' },
    { value: 'rajkot', displayValue: 'Rajkot' },
    { value: 'rajmahal', displayValue: 'Rajmahal' },
    { value: 'rajnandgaon', displayValue: 'Rajnandgaon' },
    { value: 'rajouri', displayValue: 'Rajouri' },
    { value: 'rajpipla', displayValue: 'Rajpipla' },
    { value: 'rajpura', displayValue: 'Rajpura' },
    { value: 'rajsamand', displayValue: 'Rajsamand' },
    { value: 'ram_nagar', displayValue: 'Ram Nagar' },
    { value: 'ramachandrapuram', displayValue: 'Ramachandrapuram' },
    { value: 'ramagundham', displayValue: 'Ramagundham' },
    { value: 'raman_mandi', displayValue: 'Raman Mandi' },
    { value: 'ramanagara', displayValue: 'Ramanagara' },
    { value: 'ramanathapuram', displayValue: 'Ramanathapuram' },
    { value: 'rameshwaram', displayValue: 'Rameshwaram' },
    { value: 'ramgarh', displayValue: 'Ramgarh' },
    { value: 'ramnagar', displayValue: 'Ramnagar' },
    { value: 'rampur', displayValue: 'Rampur' },
    { value: 'rampurhat', displayValue: 'Rampurhat' },
    { value: 'ranaghat', displayValue: 'Ranaghat' },
    { value: 'ranakpur', displayValue: 'Ranakpur' },
    { value: 'ranchi', displayValue: 'Ranchi' },
    { value: 'ranebennur', displayValue: 'Ranebennur' },
    { value: 'raniganj', displayValue: 'Raniganj' },
    { value: 'ranikhet', displayValue: 'Ranikhet' },
    { value: 'ranny', displayValue: 'Ranny' },
    { value: 'ranthambore', displayValue: 'Ranthambore' },
    { value: 'ratangarh', displayValue: 'Ratangarh' },
    { value: 'ratlam', displayValue: 'Ratlam' },
    { value: 'ratnagiri', displayValue: 'Ratnagiri' },
    { value: 'ravangla', displayValue: 'Ravangla' },
    { value: 'rayachoty', displayValue: 'Rayachoty' },
    { value: 'rayagada', displayValue: 'Rayagada' },
    { value: 'rewa', displayValue: 'Rewa' },
    { value: 'rewari', displayValue: 'Rewari' },
    { value: 'rishikesh', displayValue: 'Rishikesh' },
    { value: 'rishyap', displayValue: 'Rishyap' },
    { value: 'rohetgarh', displayValue: 'Rohetgarh' },
    { value: 'rohtak', displayValue: 'Rohtak' },
    { value: 'roorkee', displayValue: 'Roorkee' },
    { value: 'ropar', displayValue: 'Ropar' },
    { value: 'rourkela', displayValue: 'Rourkela' },
    { value: 'rudrapur', displayValue: 'Rudrapur' },
    { value: 'rupnagar', displayValue: 'Rupnagar' },
    { value: 'sagar', displayValue: 'Sagar' },
    { value: 'saharanpur', displayValue: 'Saharanpur' },
    { value: 'saharsa', displayValue: 'Saharsa' },
    { value: 'sahibganj', displayValue: 'Sahibganj' },
    { value: 'sailu', displayValue: 'Sailu' },
    { value: 'sajan', displayValue: 'Sajan' },
    { value: 'sakoli', displayValue: 'Sakoli' },
    { value: 'sakri', displayValue: 'Sakri' },
    { value: 'salem', displayValue: 'Salem' },
    { value: 'samalkha', displayValue: 'Samalkha' },
    { value: 'samana', displayValue: 'Samana' },
    { value: 'samastipur', displayValue: 'Samastipur' },
    { value: 'sambalpur', displayValue: 'Sambalpur' },
    { value: 'sambhal', displayValue: 'Sambhal' },
    { value: 'sanand', displayValue: 'Sanand' },
    { value: 'sandila', displayValue: 'Sandila' },
    { value: 'sangamner', displayValue: 'Sangamner' },
    { value: 'sangareddi', displayValue: 'Sangareddi' },
    { value: 'sangli fata', displayValue: 'Sangli Fata' },
    { value: 'sangli', displayValue: 'Sangli' },
    { value: 'sangola', displayValue: 'Sangola' },
    { value: 'sangrur', displayValue: 'Sangrur' },
    { value: 'santipur', displayValue: 'Santipur' },
    { value: 'saoner', displayValue: 'Saoner' },
    { value: 'saputara', displayValue: 'Saputara' },
    { value: 'saraikela', displayValue: 'Saraikela' },
    { value: 'sarangpur', displayValue: 'Sarangpur' },
    { value: 'sardarshahar', displayValue: 'Sardarshahar' },
    { value: 'sasan_gir', displayValue: 'Sasan Gir' },
    { value: 'sasaram', displayValue: 'Sasaram' },
    { value: 'satara', displayValue: 'Satara' },
    { value: 'satna', displayValue: 'Satna' },
    { value: 'sattal', displayValue: 'Sattal' },
    { value: 'saunda', displayValue: 'Saunda' },
    { value: 'sawai_madhopur', displayValue: 'Sawai Madhopur' },
    { value: 'sawaimadhopur', displayValue: 'SawaiMadhopur' },
    { value: 'sawantwadi', displayValue: 'Sawantwadi' },
    { value: 'sawarde', displayValue: 'Sawarde' },
    { value: 'sayla', displayValue: 'Sayla' },
    { value: 'sayra', displayValue: 'Sayra' },
    { value: 'secunderabad', displayValue: 'Secunderabad' },
    { value: 'sehore', displayValue: 'Sehore' },
    { value: 'selu', displayValue: 'Selu' },
    { value: 'seoni', displayValue: 'Seoni' },
    { value: 'sevagram', displayValue: 'Sevagram' },
    { value: 'shahagad', displayValue: 'Shahagad' },
    { value: 'shahapur', displayValue: 'Shahapur' },
    { value: 'shahdol', displayValue: 'Shahdol' },
    { value: 'shahjahanpur', displayValue: 'Shahjahanpur' },
    { value: 'shahpura', displayValue: 'Shahpura' },
    { value: 'shajapur', displayValue: 'Shajapur' },
    { value: 'shaktinagar', displayValue: 'Shaktinagar' },
    { value: 'shamli', displayValue: 'Shamli' },
    { value: 'shantiniketan', displayValue: 'Shantiniketan' },
    { value: 'sharavanbelgola', displayValue: 'Sharavanbelgola' },
    { value: 'shegaon', displayValue: 'Shegaon' },
    { value: 'sheikhpura', displayValue: 'Sheikhpura' },
    { value: 'shelapur', displayValue: 'Shelapur' },
    { value: 'sheopur', displayValue: 'Sheopur' },
    { value: 'shikohabad', displayValue: 'Shikohabad' },
    { value: 'shillong', displayValue: 'Shillong' },
    { value: 'shimla', displayValue: 'Shimla' },
    { value: 'shimlipal', displayValue: 'Shimlipal' },
    { value: 'shimoga', displayValue: 'Shimoga' },
    { value: 'shirdi', displayValue: 'Shirdi' },
    { value: 'shivamogga', displayValue: 'Shivamogga' },
    { value: 'shivanasamudra', displayValue: 'Shivanasamudra' },
    { value: 'shivpuri', displayValue: 'Shivpuri' },
    { value: 'shravanabelagola', displayValue: 'Shravanabelagola' },
    { value: 'shrirampur', displayValue: 'Shrirampur' },
    { value: 'shrirangapattana', displayValue: 'Shrirangapattana' },
    { value: 'siana', displayValue: 'Siana' },
    { value: 'siddapur', displayValue: 'Siddapur' },
    { value: 'siddhpur', displayValue: 'Siddhpur' },
    { value: 'siddipet', displayValue: 'Siddipet' },
    { value: 'sidhi', displayValue: 'Sidhi' },
    { value: 'sidlaghatta', displayValue: 'Sidlaghatta' },
    { value: 'sikandrabad', displayValue: 'Sikandrabad' },
    { value: 'sikar', displayValue: 'Sikar' },
    { value: 'silchar', displayValue: 'Silchar' },
    { value: 'siliguri', displayValue: 'Siliguri' },
    { value: 'silvassa', displayValue: 'Silvassa' },
    { value: 'singhana', displayValue: 'Singhana' },
    { value: 'singrauli', displayValue: 'Singrauli' },
    { value: 'sircilla', displayValue: 'Sircilla' },
    { value: 'sirkazhi', displayValue: 'Sirkazhi' },
    { value: 'sirohi', displayValue: 'Sirohi' },
    { value: 'sironj', displayValue: 'Sironj' },
    { value: 'sirsa', displayValue: 'Sirsa' },
    { value: 'sitamarhi', displayValue: 'Sitamarhi' },
    { value: 'sitapur', displayValue: 'Sitapur' },
    { value: 'siuri', displayValue: 'Siuri' },
    { value: 'sivaganga_district', displayValue: 'Sivaganga District' },
    { value: 'sivakasi', displayValue: 'Sivakasi' },
    { value: 'sivasagar', displayValue: 'Sivasagar' },
    { value: 'siwan', displayValue: 'Siwan' },
    { value: 'solan', displayValue: 'Solan' },
    { value: 'solapur', displayValue: 'Solapur' },
    { value: 'sonauli', displayValue: 'Sonauli' },
    { value: 'sonipat', displayValue: 'Sonipat' },
    { value: 'sonkhed', displayValue: 'Sonkhed' },
    { value: 'sreerampur', displayValue: 'Sreerampur' },
    { value: 'sri_dungargarh', displayValue: 'Sri Dungargarh' },
    { value: 'sri_ganganagar', displayValue: 'Sri Ganganagar' },
    { value: 'srikakulam', displayValue: 'Srikakulam' },
    { value: 'srinagar', displayValue: 'Srinagar' },
    { value: 'srivilliputhur', displayValue: 'Srivilliputhur' },
    { value: 'sujangarh', displayValue: 'Sujangarh' },
    { value: 'sultanpur', displayValue: 'Sultanpur' },
    { value: 'sulthan_bathery', displayValue: 'Sulthan Bathery' },
    { value: 'sundar_nagar', displayValue: 'Sundar Nagar' },
    { value: 'sundargarh', displayValue: 'Sundargarh' },
    { value: 'sunder_nagar', displayValue: 'Sunder Nagar' },
    { value: 'sunderban', displayValue: 'Sunderban' },
    { value: 'supaul', displayValue: 'Supaul' },
    { value: 'surat', displayValue: 'Surat' },
    { value: 'suratgarh', displayValue: 'Suratgarh' },
    { value: 'surendranagar', displayValue: 'Surendranagar' },
    { value: 'suri', displayValue: 'Suri' },
    { value: 'suryapet', displayValue: 'Suryapet' },
    { value: 'tadepalligudem', displayValue: 'Tadepalligudem' },
    { value: 'tajpur', displayValue: 'Tajpur' },
    { value: 'takari', displayValue: 'Takari' },
    { value: 'talegadh', displayValue: 'Talegadh' },
    { value: 'talegaon', displayValue: 'Talegaon' },
    { value: 'taliparamba', displayValue: 'Taliparamba' },
    { value: 'talluru', displayValue: 'Talluru' },
    { value: 'tamluk', displayValue: 'Tamluk' },
    { value: 'tanda', displayValue: 'Tanda' },
    { value: 'tandur', displayValue: 'Tandur' },
    { value: 'tanjore', displayValue: 'Tanjore' },
    { value: 'tanuku', displayValue: 'Tanuku' },
    { value: 'tapola', displayValue: 'Tapola' },
    { value: 'tarapith', displayValue: 'Tarapith' },
    { value: 'tarkeswar', displayValue: 'Tarkeswar' },
    { value: 'tarn_taran', displayValue: 'Tarn Taran' },
    { value: 'tasgaon', displayValue: 'Tasgaon' },
    { value: 'tehri', displayValue: 'Tehri' },
    { value: 'tenkasi', displayValue: 'Tenkasi' },
    { value: 'tezpur', displayValue: 'Tezpur' },
    { value: 'tezu', displayValue: 'Tezu' },
    { value: 'thalassery', displayValue: 'Thalassery' },
    { value: 'thamarassery', displayValue: 'Thamarassery' },
    { value: 'thane', displayValue: 'Thane' },
    { value: 'thanjavur', displayValue: 'Thanjavur' },
    { value: 'thattarmadam', displayValue: 'Thattarmadam' },
    { value: 'thekkady', displayValue: 'Thekkady' },
    { value: 'theni', displayValue: 'Theni' },
    { value: 'thennilai', displayValue: 'Thennilai' },
    { value: 'thirumangalam', displayValue: 'Thirumangalam' },
    { value: 'thirunallar', displayValue: 'Thirunallar' },
    { value: 'thirupattur', displayValue: 'Thirupattur' },
    { value: 'thiruvalla', displayValue: 'Thiruvalla' },
    { value: 'thiruvananthapuram', displayValue: 'Thiruvananthapuram' },
    { value: 'thiruvanmiyur', displayValue: 'Thiruvanmiyur' },
    { value: 'thirvannamalai', displayValue: 'Thirvannamalai' },
    { value: 'thodupuzha', displayValue: 'Thodupuzha' },
    { value: 'thoothukudi', displayValue: 'Thoothukudi' },
    { value: 'thottiyam', displayValue: 'Thottiyam' },
    { value: 'thoubal', displayValue: 'Thoubal' },
    { value: 'thrissur', displayValue: 'Thrissur' },
    { value: 'thuraiyur', displayValue: 'Thuraiyur' },
    { value: 'tikamgarh', displayValue: 'Tikamgarh' },
    { value: 'tindivanam', displayValue: 'Tindivanam' },
    { value: 'tinsukia', displayValue: 'Tinsukia' },
    { value: 'tiptur', displayValue: 'Tiptur' },
    { value: 'tiruchchirappalli', displayValue: 'Tiruchchirappalli' },
    { value: 'tiruchirapalli', displayValue: 'Tiruchirapalli' },
    { value: 'tirunelveli', displayValue: 'Tirunelveli' },
    { value: 'tirupati', displayValue: 'Tirupati' },
    { value: 'tiruppur', displayValue: 'Tiruppur' },
    { value: 'tirupur', displayValue: 'Tirupur' },
    { value: 'tirur', displayValue: 'Tirur' },
    { value: 'tiruttani', displayValue: 'Tiruttani' },
    { value: 'tiruvannamalai', displayValue: 'Tiruvannamalai' },
    { value: 'tirwaganj', displayValue: 'Tirwaganj' },
    { value: 'tisgaon', displayValue: 'Tisgaon' },
    { value: 'titagarh', displayValue: 'Titagarh' },
    { value: 'tohana', displayValue: 'Tohana' },
    { value: 'tonk', displayValue: 'Tonk' },
    { value: 'triprayar', displayValue: 'Triprayar' },
    { value: 'tumakuru', displayValue: 'Tumakuru' },
    { value: 'tumkur', displayValue: 'Tumkur' },
    { value: 'tuni', displayValue: 'Tuni' },
    { value: 'udaipur', displayValue: 'Udaipur' },
    { value: 'udayagiri', displayValue: 'Udayagiri' },
    { value: 'udgir', displayValue: 'Udgir' },
    { value: 'udhagamandalam', displayValue: 'Udhagamandalam' },
    { value: 'udhampur', displayValue: 'Udhampur' },
    { value: 'udumalaipettai', displayValue: 'Udumalaipettai' },
    { value: 'udupi', displayValue: 'Udupi' },
    { value: 'ujjain', displayValue: 'Ujjain' },
    { value: 'ulhasnagar', displayValue: 'Ulhasnagar' },
    { value: 'umaria', displayValue: 'Umaria' },
    { value: 'umarkhed', displayValue: 'Umarkhed' },
    { value: 'umred', displayValue: 'Umred' },
    { value: 'umreth', displayValue: 'Umreth' },
    { value: 'una', displayValue: 'Una' },
    { value: 'undri', displayValue: 'Undri' },
    { value: 'unguturu', displayValue: 'Unguturu' },
    { value: 'unjha', displayValue: 'Unjha' },
    { value: 'unnao', displayValue: 'Unnao' },
    { value: 'upleta', displayValue: 'Upleta' },
    { value: 'uppoor', displayValue: 'Uppoor' },
    { value: 'uppur', displayValue: 'Uppur' },
    { value: 'uran_islampur', displayValue: 'Uran Islampur' },
    { value: 'uranipuram', displayValue: 'Uranipuram' },
    { value: 'uttarkashi', displayValue: 'Uttarkashi' },
    { value: 'vadodara', displayValue: 'Vadodara' },
    { value: 'vagamon', displayValue: 'Vagamon' },
    { value: 'vaijapur', displayValue: 'Vaijapur' },
    { value: 'valluru', displayValue: 'Valluru' },
    { value: 'valsad', displayValue: 'Valsad' },
    { value: 'vanda', displayValue: 'Vanda' },
    { value: 'vapi', displayValue: 'Vapi' },
    { value: 'varanasi', displayValue: 'Varanasi' },
    { value: 'varkala', displayValue: 'Varkala' },
    { value: 'vasai-virar', displayValue: 'Vasai-Virar' },
    { value: 'vatakara', displayValue: 'Vatakara' },
    { value: 'veeravasaram', displayValue: 'Veeravasaram' },
    { value: 'velankanni', displayValue: 'Velankanni' },
    { value: 'vellakovil', displayValue: 'Vellakovil' },
    { value: 'vellore', displayValue: 'Vellore' },
    { value: 'venthanpatti', displayValue: 'Venthanpatti' },
    { value: 'veraval', displayValue: 'Veraval' },
    { value: 'vidisha', displayValue: 'Vidisha' },
    { value: 'vijainagar', displayValue: 'Vijainagar' },
    { value: 'vijayapura', displayValue: 'Vijayapura' },
    { value: 'vijayawada', displayValue: 'Vijayawada' },
    { value: 'vikramgadh', displayValue: 'Vikramgadh' },
    { value: 'viluppuram', displayValue: 'Viluppuram' },
    { value: 'viramgam', displayValue: 'Viramgam' },
    { value: 'visakhapatnam', displayValue: 'Visakhapatnam' },
    { value: 'vishakapatnam', displayValue: 'Vishakapatnam' },
    { value: 'vizianagaram', displayValue: 'Vizianagaram' },
    { value: 'vyara', displayValue: 'Vyara' },
    { value: 'wadsa', displayValue: 'Wadsa' },
    { value: 'wadwani', displayValue: 'Wadwani' },
    { value: 'wanaparthy', displayValue: 'Wanaparthy' },
    { value: 'wankaner', displayValue: 'Wankaner' },
    { value: 'warangal', displayValue: 'Warangal' },
    { value: 'wardha', displayValue: 'Wardha' },
    { value: 'warora', displayValue: 'Warora' },
    { value: 'warud', displayValue: 'Warud' },
    { value: 'washi', displayValue: 'Washi' },
    { value: 'washim', displayValue: 'Washim' },
    { value: 'watrap', displayValue: 'Watrap' },
    { value: 'wayanad', displayValue: 'Wayanad' },
    { value: 'wokha', displayValue: 'Wokha' },
    { value: 'yamunotri', displayValue: 'Yamunotri' },
    { value: 'yanam', displayValue: 'Yanam' },
    { value: 'yavatmal', displayValue: 'Yavatmal' },
    { value: 'yemmiganur', displayValue: 'Yemmiganur' },
    { value: 'yeola', displayValue: 'Yeola' },
    { value: 'yercaud', displayValue: 'Yercaud' },
    { value: 'yevla', displayValue: 'Yevla' },
    { value: 'yuksom', displayValue: 'Yuksom' },
    { value: 'zira', displayValue: 'Zira' },
    { value: 'zunheboto', displayValue: 'Zunheboto' },
];

export const documentNameMap = {
    DEATH_CERTIFICATE: 'Death Certificate',
    MEDICAL_PRESCRIPTION: 'Medical Prescription',
    POST_MORTEM_REPORT: 'Post Mortem Report',
    NO_OBJECTION_LETTER: 'No Objection Letter',
    KYC_DOCUMENTS: 'KYC Documents',
    HOSPITAL_MEDICAL_PAPERS: 'Hospital medical papers',
    INVESTIGATION_REPORTS: 'Investigation Reports',
    MORTAL_REMAINS_REPATRIATION: 'Mortal remains Repatriation',
    PAYMENT_INVOICE: 'Payment Invoice',
    VENDOR_INVESTIGATION_REPORT: 'Vendor Investigation Report',
    ALL_INDIA_PERMIT: 'All India permit',
    AML_ADDRESS_PROOF: 'AML address proof',
    AML_ID_PROOF: 'AML id proof',
    AML_PHOTO: 'AML photo',
    BANK_FORECLOSURE_STATEMENT: 'Bank foreclosure statement',
    CANCELLED_CHEQUE: 'Cancelled check',
    DL: 'DL',
    FIR: 'FIR',
    FITNESS_CERTIFICATE: 'Fitness certificate',
    ITR: 'ITR',
    LEGAL_HEIR_CERTIFICATE: 'Legar heir certificate',
    LETTER_OF_INDEMNITY: 'Letter of indemnity',
    LOAD_CHALLAN: 'Load challan',
    LETTER_OF_SUBROGATION: 'Letter of subrogation',
    NOC_FROM_FINANCER_AFTER_SETTLEMENT: 'Noc from financer after settlement',
    NOC_FROM_FINANCER_FOR_SETTLEMENT: 'Noc from financer for settlement',
    NON_REPOSSESSION_LETTER_FROM_FINANCER:
        'Non repossession letter from financer',
    NTC: 'NTC',
    ORIGINAL_SET_OF_KEYS: 'Original set of keys',
    POST_CLAIM_SETTLEMENT_LETTER_TO_NCRB:
        'Post claim settlement letter to NCRB',
    POST_CLAIM_SETTLEMENT_LETTER_TO_POLICE_STATION:
        'Post claim settlement letter to police station',
    POST_CLAIM_SETTLEMENT_LETTER_TO_RTO: 'Post claim settlement letter to RTO',
    PYP: 'PYP',
    RC: 'RC',
    RELATIONSHIP_PROOF: 'Relationship proof',
    CLAIM_FORM: 'Claim form',
    CONSENT_NON_REPAIR_CLAIMS: 'Consent non repair claims',
    FINANCIER_NEFT_DETAILS: 'Financer neft details',
    REJECTION_LETTER: 'Rejection letter',
    STATE_PERMIT: 'State permit',
    TAX_PAID_RECEIPT: 'Tax paid receipt',
    THEFT_INTIMATION_LETTER_TO_NCRB: 'Theft information from NCRB',
    THEFT_INTIMATION_LETTER_TO_RTO: 'Theft information from RTO',
    TRANSFER_FORM: 'Transfer form',
    VEHICLE_PURCHASE_INVOICE: 'Vehicle purchase invoice',
    VEHICLE_DELIVERY_CHALLAN: 'Vehicle delivery challan',
    INSURED_STATEMENT: 'Insured statement',
    EYE_WITNESS_STATEMENT: 'Eye witness statement',
    SPOT_PHOTOS: 'Spot photos',
    QST_REPORT: 'QST report',
    RC_EXTRACT: 'RC Extract',
    RECOVERY_PHOTOS: 'Recovery photos',
    WITHDRAWAL_LETTER: 'Withdrawl letter',
    APPROVAL_EMAIL: 'Approval email',
    OTHER: 'Other',
    COMMUNICATION_LETTER: 'Communication letter',
    COMPUTATION_SHEET: 'Computation Sheet',
    AMBULANCE_BILL: 'Ambulance Bill',
    DISCHARGE_VOUCHER: 'Discharge voucher',
    FLIGHT_TICKET: 'Flight ticket',
    HOSPITAL_BILL: 'Hospital Bill',
    MEDICINE_BILL: 'Medicine Bill',
    ORIGINAL_PRESCRIPTION: 'Original Prescription',
    PAYMENT_RECEIPT: 'Payment Receipt',
    BANK_ACCOUNT_SUMMARY: 'Bank Account Summary',
    BANK_ACCOUNT_STATEMENT: 'Bank account statement',
    HOSPITAL_DISCHARGE_SUMMARY: 'Hospital discharge summary',
    PURCHASE_INVOICE: 'Device purchase invoice',
    HOTEL_STAY_INVOICE: 'Hotel stay invoice',
    PROOF_OF_OWNERSHIP: 'Proof of ownership',
    SALARY_SLIPS: 'Salary slips',
    TRAVEL_TICKETS: 'Travel tickets',
    FIRE_BRIGADE_REPORT: 'Fire brigade report',
    SERVICE_TERMINATION_LETTER: 'Service termination letter',
    REPORTS: 'Reports',
    DISABILITY_CERTIFICATE: 'Disability certificate',
    DISCHARGE_SUMMARY: 'Discharge summary',
    EMPLOYER_CONFIRMATION: 'Employer confirmation',
    PROGRESS_NOTES: 'Progress notes',
    HOSPITAL_FINAL_BILL: 'Hospital final bill',
    MEDICAL_BILLS_PRE_HOSPITALIZATION: 'Medical bills pre hospitalization',
    MEDICAL_BILLS_POST_HOSPITALIZATION: 'Medical bills post hospitalization',
    AIRLINES_NO_SHOW_CONFIRMATION: 'Airlines no show confirmation',
    REFUND_DETAILS: 'Refund details',
    EXPENSE_INVOICE: 'Expense invoice',
    MEDICAL_INVOICE:
        'Medical Bills (Consultation Receipt, Pharmacy bills, Diagnostic bills)',
    DIAGNOSIS_REPORT: 'Diagnosis report',
    AMORTIZATION_STATEMENT: 'Amortization statement',
    DAMAGE_VIDEO: 'Damage Video',
    P_SLIP: 'P Slip',
    SERIAL_NUMBER_IMAGE: 'Serial Number Image',
    INVOICE: 'Invoice',
    CONFIRMATION_OF_DELAY_BY_COMMON_CARRIER:
        'Confirmation of delay by common carrier',
    NEW_FLIGHT_TICKET: 'New Flight Ticket',
    COURT_PETITION_AND_JUDGEMENT: 'Court Petition & Judgement',
    EMERGENCY_STAY_INVOICE: 'Emergency Stay Invoice',
    CONFIRMATION_OF_DELAY_FROM_AIRLINES: 'Confirmation of delay from airlines',
    CONFIRMATION_OF_DELAY_BY_THE_COMMON_CARRIER:
        'Confirmation of delay by the common carrier',
    CONFIRMATION_OF_CANCELLATION_FROM_THE_COMMON_CARRIER:
        'Confirmation of cancellation from the common carrier',
    CONFIRMATION_FROM_AIRLINES: 'Confirmation from airlines',
    MISSED_FLIGHT_TICKET: 'Missed Flight Ticket',
    TRIP_CANCELLATION_PROOF: 'Trip Cancellation Proof',
    NATURAL_CATASTROPHE_PROOF: 'Natural Catastrophe Proof',
    REPAIR_INVOICE: 'Repair Invoice',
    REPAIR_FRONT_IMAGE: 'Repaired Device Image - Front',
    REPAIR_BACK_IMAGE: 'Repaired Device Image - Back',
    REPAIR_ESTIMATE: 'Repair Estimate',
    COVID_POSITIVE_REPORT: 'Covid Positive Report',
    PLAN_PURCHASE_INVOICE: 'Plan Purchase Invoice',
    DAMAGE_FRONT_IMAGE: 'Front side of damaged mobile',
    DAMAGE_BACK_IMAGE: 'Back side of damaged mobile',
    RESIDENTIAL_PROOF: 'Residential Proof',
    PROOF_OF_RELATIONSHIP_WITH_INSURED: 'Proof Of Relationship With Insured',
    INVOICE_STOLEN_DAMAGED_ITEMS: 'Invoice Of Stolen Damaged Items',
    VEHICLE_IMAGES_AFTER_ACCIDENT: 'Vehicle Images After Accident',
    VEHICLE_IMAGES_BEFORE_TRIP: 'Vehicle Images Before Trip',
    SHOPKEEPERS_LICENSE: 'Shopkeeper’s license',
    DAMAGED_GOOD_PHOTO_VIDEO: 'Photos/Video of damaged goods',
    FAR: 'Fire Accidental report (F.A.R)',
    EQUIPMENT_IMAGE: 'Image of Damaged/Stolen Goods',
    AMBULANCE_INVOICE: 'Ambulance Invoice',
    CONSULTATION_REPORT: 'Consultation Report',
    CONSULTATION_INVOICE: 'Consultation/Diagnostics Invoice',
    INCIDENT_DETAIL_100_WORDS: 'Incident Detail in 100 words',
    DAMAGE_PHOTO: 'Damage Photo',
    REPLACE_DEVICE_INVOICE: 'Replaced Device Invoice',
    AMAZON_DEVICE_PURCHASE_INVOICE: 'Amazon device purchase Invoice',
    ACKO_PLAN_AMAZON_PURCHASE_INVOICE: 'Acko plan amazon purchase Invoice',
    REPLACED_DEVICE_AMAZON_INVOICE: 'Replaced device Amazon Invoice',
    DEVICE_AMAZON_P_SLIP: 'Device Amazon P-slip',
    ACKO_PLAN_AMAZON_P_SLIP: 'Acko plan Amazon P-slip',
    INDOOR_CASE_PAPERS: 'Indoor Case Papers',
    MEDICAL_CERTIFICATE: 'Medical Certificate',
    REPAIR_IMAGE: 'Repair Images/Videos',
    BRAND_ESTIMATION:
        'Estimation from the brand or GST authorized service center',
    EXPERIENCE_LETTER: 'Experience Letter',
    PF_STATEMENT_SALARY_SLIPS: 'PF statements or Salary Slips',
    RELIEVING_LETTER: 'Relieving Letter',
    NO_SHOW_TRAVEL_CERTIFICATE: 'No Show Travel Certificate',
};

export const categoryMap = {
    mobile: 'Mobile',
    laundry: 'Laundry',
    cooking: 'Cooking',
    television: 'Television',
    dishwashers: 'Dishwashers',
    refrigerator: 'Refrigerator',
    microwave_oven: 'Microwave Oven',
    air_conditioner: 'Air Conditioner',
    misc_kitchen: 'Misc Kitchen',
    audio: 'Audio',
    camera: 'Camera',
    appliance: 'Appliance',
    laptop: 'Laptop',
    air_cooler: 'Air Cooler',
    echo: 'Echo',
    fireTVStick: 'FireTVStick',
    kindle: 'Kindle',
    headphone: 'Headphone',
    home_theatre: 'Home Theatre',
    speaker: 'Speaker',
    computer_speaker: 'Computer Speaker',
    headset: 'Headset',
    DSLR_and_mirrorless_cameras: 'Dslr',
    point_and_shoot_camera: 'Camera',
    water_purifier: 'Water Purifier',
    desktop: 'Desktop',
    handy_camera: 'Handy Camera',
    printer: 'Printer',
    vaccum_cleaner: 'Vaccum Cleaner',
    dslr: 'Dslr',
    music_system: 'Music System',
    chimney: 'Chimney',
    induction: 'Induction',
    water_heater: 'Water Heater',
    tablets: 'Tablets',
    wearables: 'Wearables',
    air_purifier: 'Air Purifier',
    dryer: 'Dryer',
    food_processor: 'Food Processor',
    fryer: 'Fryer',
    otg: 'Otg',
    washing_machine: 'Washing Machine',
    audio_system: 'Audio System',
    blender_chopper: 'Blender Chopper',
    coffee_maker: 'Coffee Maker',
    dough_maker: 'Dough Maker',
    electric_iron: 'Electric Iron',
    electric_kettle: 'Electric Kettle',
    electric_rice_cooker: 'Electric Rice Cooker',
    electric_room_heater: 'Electric Room Heater',
    induction_cooktop: 'Induction Cooktop',
    kitchen_chimney: 'Chimney',
    scanner: 'Scanner',
    roti_maker: 'Roti Maker',
    sandwich_maker: 'Sandwich Maker',
    toaster: 'Toaster',
    mixer: 'Mixer',
    juicer: 'Juicer',
    grinder: 'Grinder',
    small_appliances: 'Appliance',
    smart_watch: 'Smart Watch',
    musical_instrument: 'Musical Instrument',
    treadmill: 'Treadmill',
    air_conditioners: 'Air Conditioner',
};

export const AckoLogoConfig = {
    gradient:
        'https://acko-brand.ackoassets.com/brand/vector-svg/gradient/gradient-horizontal.svg',
    light: 'https://acko-brand.ackoassets.com/brand/vector-svg/gradient/horizontal-reverse.svg',
    favicon: 'https://acko-brand.ackoassets.com/favicon/favicon.ico',
    download:
        'https://acko-brand.ackoassets.com/download/acko-download-logo.svg',
};
