/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import { getImageBasePath } from '@utils/generics';
import { AckoLogoConfig } from '@utils/constants';
import * as Styles from './styles';

function Footer() {
    return (
        <Styles.Wrapper>
            <Styles.ContentWrapper>
                <Styles.AddressWrapper>
                    <div>
                        <Image
                            src={AckoLogoConfig.light}
                            alt={''}
                            width={100}
                            height={30}
                        />
                    </div>
                    <Styles.Text>
                        Acko Technology & Services Private Limited
                    </Styles.Text>
                    <Styles.Text>
                        #36/5, Hustlehub One East, Somasandrapalya, 27th Main
                        Rd, Sector 2, HSR Layout, Bengaluru, Karnataka 560102.
                    </Styles.Text>
                    <Styles.Text>CIN: U74110KA2016PTC120161</Styles.Text>
                </Styles.AddressWrapper>
                <Styles.ContactUsWrapper>
                    <Styles.ContactUs>
                        <img
                            src={getImageBasePath('/icons/call.png')}
                            alt={''}
                            width={20}
                            height={20}
                        />
                        <Styles.Text style={{ marginBottom: 0 }}>
                            Call us on <span> 1800 266 2256 </span>
                        </Styles.Text>
                    </Styles.ContactUs>
                    <Styles.ContactUs>
                        <img
                            src={getImageBasePath('/icons/email.png')}
                            alt={''}
                            width={20}
                            height={20}
                        />
                        <Styles.Text style={{ marginBottom: 0 }}>
                            Write to us at <span>ecare@acko.tech</span>
                        </Styles.Text>
                    </Styles.ContactUs>
                </Styles.ContactUsWrapper>
                <Styles.AboutUs></Styles.AboutUs>
            </Styles.ContentWrapper>
        </Styles.Wrapper>
    );
}

export default Footer;
